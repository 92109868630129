import { Form, Input, Select } from "antd";

import {
    useResolutionOptions,
    useTranscodersGroupsOptions,
    useTranscodersServersOptions,
} from "../../../../../hooks/selectOptions";

import { outputTypeOptions } from "../../../../../config/config";

import ButtonComponent from "../../../../../_components/button/ButtonComponent";

export default function FormBlock({
    form,
    onFinish,
    isLoading,
    onSelectResolution,
    onDeselectResolution,
    setOutputType,
    setInputValue,
}) {
    const resolutionOptions = useResolutionOptions();
    const serverOptions = useTranscodersServersOptions();
    const groupsoptions = useTranscodersGroupsOptions();

    const onSelect = (value) => {
        const finded = resolutionOptions.find((item) => item.id === value);

        onSelectResolution(finded);
    };

    const onDeselect = (value) => {
        onDeselectResolution(value);
    };

    return (
        <div style={{ width: 300 }}>
            <Form
                form={form}
                name="transcoders-transcoding"
                layout="vertical"
                onFinish={onFinish}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        form.submit();
                    }
                }}
                initialValues={{
                    output_type: outputTypeOptions[0].value,
                }}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please input name",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Input"
                    name="input"
                    rules={[
                        {
                            required: true,
                            message: "Please input input",
                        },
                    ]}
                >
                    <Input onChange={(e) => setInputValue(e.target.value)} />
                </Form.Item>

                <Form.Item
                    label="Output type"
                    name="output_type"
                    rules={[
                        {
                            required: true,
                            message: "Please select type",
                        },
                    ]}
                >
                    <Select options={outputTypeOptions} onChange={setOutputType} />
                </Form.Item>

                <Form.Item
                    label="Group"
                    name="groupId"
                    rules={[
                        {
                            required: true,
                            message: "Please select group",
                        },
                    ]}
                >
                    <Select options={groupsoptions} />
                </Form.Item>

                <Form.Item
                    label="Server"
                    name="serverId"
                    rules={[
                        {
                            required: true,
                            message: "Please select server",
                        },
                    ]}
                >
                    <Select options={serverOptions} />
                </Form.Item>

                <Form.Item
                    label="Resolutions"
                    name="resolutions"
                    rules={[
                        {
                            required: true,
                            message: "Please select resolution",
                        },
                    ]}
                >
                    <Select
                        mode="multiple"
                        options={resolutionOptions}
                        onSelect={onSelect}
                        onDeselect={onDeselect}
                    />
                </Form.Item>

                <Form.Item style={{ textAlign: "center" }}>
                    <ButtonComponent
                        title="Save"
                        actionType="save"
                        isLoading={isLoading}
                        onClick={() => form.submit()}
                    />
                </Form.Item>
            </Form>
        </div>
    );
}
