import React, { useEffect, useState } from "react";

import requests from "../server/requests";

import { parseDate } from "../helpers/formats";

import QuestionPopup from "../popups/QuestionsPopup";

import TableImage from "../components/TableImage";
import TableComponent from "../components/TableComponent";
import MovieStatistic from "../components/MovieStatistic";
import TableEmptyComponent from "../components/TableEmptyComponent";
import TableLoadingComponent from "../components/TableLoadingComponent";
import { useLocation, useParams } from "react-router";

export default function ContentProviderPage(props) {
  const [tops, setTops] = useState([]);
  const [profile, setProfile] = useState(null);
  const [watchedList, setWarchedList] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [showLogout, setShowLogout] = useState(false);

  const location = useLocation();

  const getTops = (token) => {
    requests.content_provider.tops(token, (data) => {
      console.log(data);
      setTops(data);
    });
  };

  const getWatchedList = (token) => {
    setShowLoading(true);

    requests.content_provider.watched_list(
      token,

      (data) => {
        setWarchedList(data);
        setShowLoading(false);
      },
      () => {
        setShowLoading(false);
      }
    );
  };

  const getProfile = (token) => {
    requests.content_provider.profile(token, (data) => {
      console.log(data, "data");

      setProfile(data);
    });
  };

  const logout = () => {
    localStorage.clear();
    window.location.href = `/#/`;
  };

  useEffect(() => {
    let token = location?.state?.token;

    getTops(token);
    getProfile(token);
    getWatchedList(token);
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <nav
        className="navbar navbar-expand-lg navbar-light d-flex justify-content-between"
        style={{ height: 60 }}
      >
        <div></div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ marginRight: 10 }}>{profile ? profile.contact_person : ""}</p>

          <button
            className={`popup-button-style logout-button`}
            onClick={() => setShowLogout(true)}
          >
            <i className="fas fa-sign-out-alt logout-size" style={{ marginRight: 5 }} />
            Logout
          </button>
        </div>
      </nav>

      <div style={{ padding: 50 }}>
        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <div className="movie-statistic">
            {tops?.map((item, index) => {
              console.log(item, "item");
              return (
                <MovieStatistic
                  index={index}
                  key={item.movieId}
                  name={item.name}
                  count={item.count}
                />
              );
            })}
          </div>
        </div>

        <h4 style={{ marginBottom: 10 }}>Watched list</h4>

        <TableComponent id="watched-list-table">
          <thead>
            <tr>
              <th style={{ width: "5%" }}>#</th>
              <th style={{ width: "15%" }}>Name</th>
              <th style={{ width: "10%" }}>Poster</th>
              <th style={{ width: "15%" }}>Year</th>
              <th style={{ width: "15%" }}>Watched count</th>
              <th style={{ width: "30%" }}>Updated At</th>
            </tr>
          </thead>

          <tbody>
            {showLoading ? (
              <TableLoadingComponent />
            ) : watchedList.length > 0 ? (
              watchedList.map((index, key) => {
                console.log(index, "index");
                return (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{index && index.name}</td>
                    <td>{index && <TableImage src={index.poster} />}</td>

                    <td>{index && index.year}</td>
                    <td>{index.watched}</td>
                    <td>{parseDate(index.updatedAt)}</td>
                  </tr>
                );
              })
            ) : (
              <TableEmptyComponent />
            )}
          </tbody>
        </TableComponent>
      </div>

      {showLogout && (
        <QuestionPopup
          type="Logout"
          onClose={() => {
            setShowLogout(false);
          }}
          title={`Do you want to Logout`}
          action={logout}
        />
      )}
    </div>
  );
}
