import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
  DEVICE: `${host}launcher/admin/device`,
};

const launcherDevicesApi = {
  GET: (query) => request("GET", URL.DEVICE, query),

  BLOCK: (query) => request("POST", URL.DEVICE, query),
};

export default launcherDevicesApi;
