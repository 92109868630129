import DurationMovieChart from "./charts/DurationMovieChart";
import PerMovieChart from "./charts/PerMovieChart";
import TopWatchedChart from "./charts/TopWatchedChart";
import Totals from "./totals/Totals";
import { Flex } from "antd";

import WatchedBlock from "./watched/WatchedBlock";
import LikeDisLikeChart from "./charts/LikeDislikeChart";
import FavoritePlatformVodChart from "./charts/FavoritePlatformVodChart";
import FavoriteCountryVodChart from "./charts/FavoriteCountryVodChart";
import LikeDisLikeCountry from "./charts/LikeDisLikeCountryChart";

export default function VodTab({}) {
  return (
    <div>
      <Totals />
      <WatchedBlock />
      <Flex wrap="wrap" gap={20} size="small">
        <TopWatchedChart />
        <PerMovieChart />
        <LikeDisLikeChart />
        <LikeDisLikeCountry />
        <DurationMovieChart />
        <FavoritePlatformVodChart />
        <FavoriteCountryVodChart />
      </Flex>
    </div>
  );
}
