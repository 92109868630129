import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { Drawer, Form, Input, Divider, Button, Select, notification } from "antd";
import { useLanguagesOptions } from "../../../../../../hooks/selectOptions";
import TextareaComponent from "../../../../../../components/TextareaComponent";
import ImageUpload from "../../../../../../components/ImageUpload";
import REQUESTS from "../../../../../../api/requests";
import TranscodingSearch from "../../../../../../_components/searches/TranscodingSearch";
import ButtonComponent from "../../../../../../_components/button/ButtonComponent";
import icons from "../../../../../../config/icons";
import Player from "../../../../../../routes/vod/medias/Player";

const AddEpisodeDearer = ({
  show,
  close,
  isEditMode,
  initialValues,
  selectedSeasonId,
  addOrUpdateEpisode,
  seasonId,
  addEpisodeTranslation,
  getTranslation,
  translations,
}) => {
  const [form] = Form.useForm();
  const [translationForm] = Form.useForm();

  const [streamType, setStreamType] = useState("");

  const [imgFile, setImgFile] = useState({
    file: null,
    url: null,
  });

  const [transcodingId, setTranscodingId] = useState(null);

  const [streamData, setStreamData] = useState({
    id: null,
    url: null,
  });
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [translationAddLoading, setTranslationAddLoading] = useState(false);
  const [urlValidation, setUrlValidation] = useState(false);

  const [translationLanguage, setTranslationLanguage] = useState();

  const [introStart, stIntroStart] = useState(0);
  const [introEnd, setIntroEnd] = useState(0);
  const [credit, setCredit] = useState(0);

  const languagesOptions = useLanguagesOptions();

  const handleSubmit = async (values) => {
    const type = !isEditMode ? "post" : "put";
    let formData = new FormData();
    console.log(imgFile);
    if (imgFile.file) {
      formData.append("poster", imgFile?.file);
    }

    formData.append("name", values?.name);
    if (values.description) {
      formData.append("description", values?.description);
    }
    if (values.intro_start_time) {
      formData.append("intro_start_time", values.intro_start_time);
    }
    if (values.credit_time_ms) {
      formData.append("credits_time", values.credit_time_ms || 0);
    }

    if (values.intro_end_time_ms) {
      formData.append("intro_end_time", values.intro_end_time_ms || 0);
    }

    formData.append("duration", values?.duration_ms || 0);

    formData.append("number", values.number);

    formData.append("stream_type", values.stream_type);

    if (values.stream_type === "internal") {
      formData.append("transcoding_id", streamData?.id);
    } else {
      formData.append("stream_url", values?.stream_url);
    }

    if (type == "put") formData.append("id", selectedSeasonId);
    if (type == "post") formData.append("seasonId", seasonId);
    if (values.release_date) formData.append("release_date", values.release_date);

    const cb = (data) => {
      form.resetFields();
      setStreamData({
        id: null,
        url: null,
      });
      setImgFile({
        file: null,
        url: null,
      });
      close();
    };

    addOrUpdateEpisode(selectedSeasonId, type, formData, cb);
  };

  const handleStreamTypeChange = (value) => {
    setStreamType(value);
  };

  const StreamTypeOPtion = [
    { name: "internal", value: "internal" },
    { name: "external", value: "external" },
  ];

  const handleShowPlayerPopup = () => {
    setOpenVideoModal(true);
  };

  const handleChange = ({ target: { value } }) => {
    const pattern = /^(https?):\/\/[^\s/$.?#].[^\s]*$/;
    const isValid = pattern.test(value);
    setUrlValidation(isValid);

    if (isValid) {
      if (streamType === "external") {
        setStreamData({ ...streamData, url: value });
      }
    }
  };

  const getStreamOnTranscoding = async (id) => {
    try {
      const response = await REQUESTS.VOD.TRANSCODING.GET({
        query: JSON.stringify({ filter: { id } }),
      });
      const transcoding = response?.rows?.[0];
      form.setFieldsValue({
        transcoding_id: transcoding?.id,
      });
      setStreamData({
        id: transcoding?.id,
        url: transcoding?.stream_url,
      });
    } catch (error) {
      console.error("Error fetching transcoding:", error);
    }
  };

  const handleSaveSkipOption = (option) => {
    form.setFieldsValue({ credit_time_ms: option?.credits });
    form.setFieldsValue({ intro_end_time_ms: option?.end });
    form.setFieldsValue({ intro_start_time: option?.start });
    form.setFieldsValue({ duration_ms: option?.duration });
  };

  const submitTranslation = (values) => {
    const body = {
      id: selectedSeasonId,
      languageId: translationLanguage,
      model: "episode",
      name: values.translation_name,
      description: values.translation_description,
    };
    setTranslationAddLoading(true);

    const success = (data) => {
      setTranslationAddLoading(false);
      notification.success({
        success: "success",
        message: "Translation added successfully",
      });
    };

    const error = (error) => {
      console.log(error);
      notification.error({
        error: "error",
        message: "Something wrong",
      });
      setTranslationAddLoading(false);
    };

    addEpisodeTranslation(body, success, error);
  };

  useEffect(() => {
    const languageId = translationForm.getFieldValue("languageId");

    if (!languageId) {
      const eng = languagesOptions.find((item) => item.iso_code === "en");
      translationForm.setFieldsValue({
        languageId: eng?.id,
      });
      setTranslationLanguage(eng?.id);
    }

    const newSeasonTranslation = translations?.find(
      (item) => item.id == translationLanguage
    );

    if (newSeasonTranslation?.episode_translation) {
      translationForm.setFields([
        {
          name: "translation_name",
          value: newSeasonTranslation.episode_translation.name || "",
        },
        {
          name: "translation_description",
          value: newSeasonTranslation.episode_translation.description || "",
        },
      ]);
    } else {
      translationForm.setFields([
        {
          name: "translation_name",
          value: "",
        },
        {
          name: "translation_description",
          value: "",
        },
      ]);
    }
  }, [translationLanguage, translationForm, translations]);

  useEffect(() => {
    if (isEditMode) {
      const value = initialValues.find((value) => value.id === selectedSeasonId);

      stIntroStart(value?.intro_start_time);
      setIntroEnd(value?.intro_end_time);
      setCredit(value?.credits_time);

      if (value) {
        form.setFieldsValue({
          name: value?.name,
          number: value?.number,
          stream_type: value?.stream_type,
          duration_ms: value?.duration,
          intro_start_time: value?.intro_start_time,
          intro_end_time_ms: value?.intro_end_time,
          credit_time_ms: value?.credits_time,
          description: value?.description,
          translation_language: "en",
          poster: value?.poster,
          stream_url: value.stream_url,
        });

        if (value.poster) {
          setImgFile({
            file: null,
            url: value.poster,
          });
        }
      }

      setStreamType(value?.stream_type);

      if (value?.stream_type === "internal") {
        getStreamOnTranscoding(value.transcoding_id);
      } else {
        setStreamData({ ...streamData, url: value?.stream_url });
      }
    } else {
    }

    getTranslation(selectedSeasonId, "episode");
  }, [isEditMode]);

  useEffect(() => {
    if (transcodingId) {
      getStreamOnTranscoding(transcodingId);
    }
  }, [transcodingId, streamType]);

  return (
    <Drawer
      title={isEditMode ? "Edit Episode" : "Add Episode"}
      open={show}
      onClose={() => {
        close();
        form?.resetFields();
        setStreamData({
          id: null,
          url: null,
        });
      }}
      width={600}
      footer={
        <div
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <Button onClick={close}>Cancel</Button>
          <Button type="primary" onClick={() => form.submit()}>
            {isEditMode ? "Save" : "Add"}
          </Button>
        </div>
      }
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          name="poster"
          label="Poster"
          rules={[{ required: !imgFile && true, message: "Poster is required" }]}
          style={{
            width: 150,
          }}
        >
          <ImageUpload
            image={imgFile}
            setImage={setImgFile}
            label={"Select Poster"}
            removeImage
          />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please enter the name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="number"
          label="Number"
          rules={[{ required: true, message: "Please enter the number" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="stream_type"
          label="Stream Type"
          rules={[{ required: true, message: "Stream type is required" }]}
        >
          <Select options={StreamTypeOPtion} onChange={handleStreamTypeChange} />
        </Form.Item>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            alignItems: "center",
          }}
        >
          {streamType === "internal" ? (
            <Form.Item
              label="Stream url"
              name="transcoding_id"
              onChange={handleChange}
              shouldUpdate
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please input a url",
                },
              ]}
            >
              <TranscodingSearch
                value={transcodingId}
                onChange={(value) => {
                  setTranscodingId(value);
                }}
              />
            </Form.Item>
          ) : (
            // <Form.Item
            //   label="Stream url"
            //   name="stream_url"
            //   style={{ width: "100%" }}
            //   onChange={handleChange}
            //   shouldUpdate
            //   rules={[
            //     {
            //       required: true,
            //       message: "Please input a url",
            //     },
            //   ]}
            // >
            //   <Input />
            // </Form.Item>
            <Form.Item
              label="Stream url"
              name="stream_url"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject(new Error("Please input a URL"));
                    }
                    if (!urlValidation && value) {
                      return Promise.reject(new Error("Please enter valid URL"));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input onChange={handleChange} />
            </Form.Item>
          )}

          <Button
            className="media-play-button"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid transparent",
              backgroundColor: "transparent",
            }}
            disabled={(streamType === "external" && !urlValidation) || !streamData?.url}
            icon={<i className="fas fa-play-circle" />}
            onClick={handleShowPlayerPopup}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: 5,
            flexWrap: "wrap",
          }}
        >
          <Form.Item
            name="duration_ms"
            label="Duration (ms)"
            rules={[{ required: true, message: "Please enter the duration" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="intro_start_time" label="Intro start (s)">
            <Input />
          </Form.Item>
          <Form.Item name="intro_end_time_ms" label="Intro End Time(s">
            <Input />
          </Form.Item>
          <Form.Item name="credit_time_ms" label="Credits time(s)">
            <Input />
          </Form.Item>
        </div>
        <Form.Item name="description" label="Description">
          <TextareaComponent />
        </Form.Item>
        {isEditMode && (
          <Form form={translationForm} layout="vertical" onFinish={submitTranslation}>
            <Divider orientation="left">Translations</Divider>

            <Form.Item name="languageId" label="Language">
              <Select
                showSearch
                options={languagesOptions}
                onChange={(e) => {
                  setTranslationLanguage(e);
                }}
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input?.toLocaleLowerCase()
                  )
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item name="translation_name" label="Name">
              <Input />
            </Form.Item>
            <Form.Item name="translation_description" label="Description">
              <TextareaComponent />
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ButtonComponent
                title={"Save translation"}
                icon={icons.CHECK}
                isLoading={translationAddLoading}
                onClick={() => {
                  translationForm.submit();
                }}
              />
            </div>
          </Form>
        )}
      </Form>

      {createPortal(
        <Player
          type="hls"
          isVisible={openVideoModal}
          src={streamData?.url}
          hideModal={() => {
            setOpenVideoModal(false);
          }}
          saveSkeepOption={(body) => handleSaveSkipOption(body)}
          introStartTime={introStart}
          introEndTime={introEnd}
          creditsTime={credit}
          useToken={streamType === "internal" ? true : false}
        />,
        document.body
      )}
    </Drawer>
  );
};

export default AddEpisodeDearer;
