import React, { useEffect, useState } from "react";
import { Button, Form, Input, Progress, Upload, notification } from "antd";

import icons from "../../../config/icons";

import TranscodingSearch from "../../../_components/searches/TranscodingSearch";

import REQUESTS from "../../../api/requests";

export default function AdsUrl({ form, type }) {
  const [transcodingSearchId, setTranscodingSearchId] = useState(null);
  const [progress, setProgress] = useState(0);

  const openAdsUrl = () => {
    const { url } = form.getFieldsValue();
    const adsUrl = `http://ads_test.inorain.com/?tag=${url}&name=custom&type=custom`;
    window.open(adsUrl, "_blank");
  };

  const handleUploadFail = (value) => {
    if (value.file.status === "done" && value.file) {
      let file = value.file.originFileObj;
      const fileName = value.file.originFileObj.name;

      REQUESTS.VOD.TRANSCODING.UPLOAD.STATUS({ file_id: file.uid + file.name })
        .then((response) => {
          const total_chunk_uploaded = response?.total_chunk_uploaded;
          const headers = {
            "starting-byte": total_chunk_uploaded,
            "file-id": file.uid + file.name,
            "chunk-size": file.size - total_chunk_uploaded,
            "file-size": file.size,
          };
          file = file.slice(total_chunk_uploaded, file.size);

          const formData = new FormData();
          formData.append("file", file, file.name);

          const onProgress = (e) => {
            setProgress(e);
          };

          REQUESTS.VOD.TRANSCODING.UPLOAD.START(formData, headers, onProgress)
            .then((response) => {
              if (response.message === "Finish") {
                setTimeout(() => {
                  const body = {
                    input: response.file_path,
                    // input_type: "file",
                    // is_public: true,
                    // name: fileName,
                  };
                  setProgress(0);
                  addTranscode(body);
                }, 1000);
              } else {
                notification.error({
                  message: "Error",
                  description: "Something is wrong",
                });
              }
            })
            .catch((error) => {
              notification.error({
                message: "Error",
                description: error?.message,
              });
            });
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description: error?.message,
          });
        });
    }
  };

  const addTranscode = (body) => {
    REQUESTS.VOD.TRANSCODING.ADD(body)
      .then((res) => {
        setTranscodingSearchId(res.id);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getTranscoding = (id) => {
    REQUESTS.VOD.TRANSCODING.GET({ query: { filter: { id } } }).then((response) => {
      if (response.rows) {
        const transcoding = response.rows[0];

        form.setFieldsValue({ url: transcoding?.stream_url });
      }
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      getTranscoding(transcodingSearchId);
    }, 0);
    return () => {
      clearTimeout(timeout);
    };
  }, [transcodingSearchId]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 10,
      }}
    >
      <Form.Item shouldUpdate noStyle>
        {() => (
          <Form.Item
            label="Url"
            name="url"
            style={{ width: "100%" }}
            rules={[{ required: true, message: "Please input url" }]}
          >
            {type === "IMA" ? (
              <Input
                onChange={(value) => {
                  if (value) {
                    form.setFieldsValue({ url: value });
                  }
                }}
              />
            ) : (
              <div style={{ display: "flex", gap: 5, width: "100%" }}>
                <TranscodingSearch
                  label="Url"
                  value={transcodingSearchId}
                  onChange={(value) => {
                    setTranscodingSearchId(value);
                  }}
                />
                <div className="ads-upload">
                  <Upload
                    maxCount={1}
                    onChange={handleUploadFail}
                    customRequest={({ file, onSuccess }) => {
                      setTimeout(() => {
                        onSuccess("ok");
                      }, 100);
                    }}
                  >
                    <Button
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flex: "1",
                      }}
                      icon={icons.FOLDER}
                    />
                  </Upload>
                </div>
              </div>
            )}
            {progress ? <Progress percent={progress} /> : ""}
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { url } = form.getFieldsValue();
          return (
            type === "IMA" && (
              <Button
                style={{
                  marginTop: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                type="primary"
                icon={icons.PLAY}
                onClick={openAdsUrl}
                disabled={!url}
              />
            )
          );
        }}
      </Form.Item>
    </div>
  );
}
