import axios from "axios";
import { inoToast } from "../components/toast/Toast";

import url, {
  channelsUrl,
  // collectionsUrl,
  // collectionUrl,
  deviceUrl,
  emailUrl,
  episodeUrl,
  streamingServerUrl,
  streamUrl,
  tmdbUrl,
  transcoderChannelUrl,
  // transcoderGroupUrl,
  transcoderServerUrl,
  usersUrl,
  soundUrl,
  soundsUrl,
  actorForTmdbUrl,
  playlistParseUrl,
  // transcodeChannelUrl,
  // transcoderLogsUrl,
  // streamerUrl,
} from "./url";

export const getToken = () => localStorage.getItem("admtoken");

const showLoading = () => {
  document.getElementById("actions-loading-style").style.display = "flex";
};

export const jsonToGetUrl = (body) => {
  let query = "?";
  let objectLength = Object.keys(body).length;
  let iterator = 0;
  for (let key in body) {
    iterator++;
    if (iterator === objectLength) {
      query += key + "=" + body[key];
    } else {
      query += key + "=" + body[key] + "&";
    }
  }

  return query;
};

let loadingTimeout = null;
let loadingTimeoutError = null;

const request = (
  method,
  url,
  params,
  cb,
  errorCallback,
  timeoutError,
  haveTimeout = true
) => {
  const headers = {
    "Content-Type": "application/json",
    authorization: `${getToken()}`,
  };

  let myAxios = axios.create();

  if (haveTimeout) {
    if (
      url === `${soundsUrl}download/youtube` ||
      url === `${soundUrl}?token=${getToken()}`
    ) {
      myAxios.defaults.timeout = 120000;
    } else if (url === playlistParseUrl) {
      myAxios.defaults.timeout = 60000 * 10;
    } else {
      myAxios.defaults.timeout = 20000;
    }
  }

  const options = {
    url,
    method,
    headers,
  };

  if (method.toUpperCase() === "GET") {
    options.params = params;
  } else {
    options.data = params;
  }

  if (headers) {
    options.headers = {
      ...options.headers,
      ...headers,
    };
  }

  myAxios(options)
    .then((response) => {
      if (response.data.message === "Forbidden") {
        window.location.href = "#";
        localStorage.clear();
        window.document.location.reload();
        return;
      }
      clearTimeout(loadingTimeout);
      loadingTimeout = setTimeout(() => {
        document.getElementById("actions-loading-style").style.display = "none";
      }, 500);

      if (response.data.error === true) {
        inoToast.error(response.data.message);

        if (response.data.message) {
          if (errorCallback) {
            errorCallback(response.data.message[0]?.msg || response.data.message);
          }
        }
      } else {
        if (cb) {
          if (response.data.message) {
            cb(response.data.message);
          } else {
            cb(response.data);
          }
        }
      }
    })

    .catch((error) => {
      inoToast.error(error.message);

      if (errorCallback) {
        errorCallback(error.message);
      }

      clearTimeout(loadingTimeoutError);
      loadingTimeoutError = setTimeout(() => {
        document.getElementById("actions-loading-style").style.display = "none";
      }, 1000);

      if (timeoutError) timeoutError("internet is slow or not connection");
    });
};

// ----- User -----

export const rGetUserCount = (cb) =>
  request("post", usersUrl + "counts", {}, (data) => cb(data));

export const rGetUserByTariff = (cb) =>
  request("post", usersUrl + "byTariff", {}, (data) => cb(data));

export const rSearchUsers = (body, cb) =>
  request("post", usersUrl + "search", body, (data) => cb(data));

// ----- ADMIN -----

// ----- CHANNELS -----

export const rGetChannelsQount = (cb) =>
  request("post", channelsUrl + "count", {}, (data) => cb(data));

export const rGetMostViewedChannels = (body, cb) =>
  request("post", channelsUrl + "most_viewed", body, cb);

// ----- EMAIL -----
export const rGetRecords = (cb) =>
  request("post", emailUrl + "admin/notifications/records", {}, (data) => cb(data));

export const rPlayPauseEmail = (id, status, cb) => {
  showLoading();
  request(
    "post",
    emailUrl + "admin/notifications/setRecordStatus",
    { id, status },
    (data) => cb(data)
  );
};

export const rSmtpSetChecked = (status, cb, cbError) => {
  showLoading();
  request("post", emailUrl + "admin/notifications/setStatus", { status }, cb, cbError);
};

export const rCheckIfSmtpEnabled = (cb, cbError) =>
  request("post", emailUrl + "admin/notifications/isEnabled", {}, cb, cbError);

export const rUpdateSmtp = (body, callback, errorCallback) => {
  showLoading();
  request("post", emailUrl + "admin/notifications/update", body, callback, errorCallback);
};

export const rDeleteEmail = (id, cb, cbError) => {
  showLoading();
  request("post", emailUrl + "admin/notifications/removeTask", { id }, cb, cbError);
};

export const rSendMeEmailNotification = (body, cb, errorCallback) => {
  showLoading();
  request("post", emailUrl + "admin/notifications/email", body, cb, errorCallback);
};

export const getSmtpNotifications = (callback, errorCallback) => {
  request("post", url.smtp_notifications, {}, callback, errorCallback);
};

// ----- PLAYLIST -----

export const requestGetDeviceCount = (cb) => request("post", deviceUrl + "count", {}, cb);

// ----- DOCUMENT -----

export const rValidateToken = (cb) =>
  request("post", `${url.admin}validate`, {}, (data) => cb(data));

// * * * * * * *  M3U8  * * * * * * *

// * * * * * * *  T R A N S C O D E R   G R O U P  * * * * * * *

// * * * * * * *  T R A N S C O D I N G   S E R V E R  * * * * * * *
export const requestGetTranscodingServer = (callback) => {
  request("post", transcoderServerUrl + "get", {}, callback);
};

// * * * * * * *  T R A N S C O D I N G  * * * * * * *
export const requestGetTranscoding = (body, callback) => {
  request("post", transcoderChannelUrl + "get", body, callback);
};

// * * * * * * *  S T R E A M I N G   S E R V E R  * * * * * * *
export const requestGetStreamServersList = (callback) => {
  request("post", streamingServerUrl + "get", {}, callback);
};

// * * * * * * *  S T R E A M I N G   S T R E A M  * * * * * * *

export const requestGetStream = (body, callback) => {
  request("post", streamUrl + "get", body, callback);
};

// * * * * * * *  M E D I A S  * * * * * * *
export const requestSearchTmdb = (name, language_id, callback) => {
  request(
    "get",
    `${tmdbUrl}serach?token=${getToken()}&q=${name}&language_id=${language_id}`,
    {},
    callback
  );
};

export const requestGetMovieInfoFromTmdb = (
  type,
  id,
  language_id,
  callback,
  errorCallback
) => {
  request(
    "get",
    `${tmdbUrl}${type}info?token=${getToken()}&tmdbId=${id}&language_id=${language_id}`,
    {},
    callback,
    errorCallback,
    null,
    false
  );
};

export const requestMovieById = (body, callback, errorCallback) => {
  request("get", `${url.movie_by_id}?id=${body.id}`, {}, callback, errorCallback);
};

export const requestRatingById = (body, callback, errorCallback) => {
  request("get", url.ratting_by_id + jsonToGetUrl(body), {}, callback, errorCallback);
};

export const requestGenresById = (body, callback, errorCallback) => {
  request("get", url.goners_by_id + jsonToGetUrl(body), {}, callback, errorCallback);
};

export const requestSeasonsById = (body, callback, errorCallback) => {
  request("get", url.season_by_id + jsonToGetUrl(body), {}, callback, errorCallback);
};

export const requestEpisodesById = (body, callback, errorCallback) => {
  request("get", url.episodes_by_id + jsonToGetUrl(body), {}, callback, errorCallback);
};

export const requestNotesById = (body, callback, errorCallback) => {
  request("get", url.notes_by_id + jsonToGetUrl(body), {}, callback, errorCallback);
};

export const requestGetMediasList = (body, callback, errorCallback) => {
  body.token = getToken();
  request("get", url.movie + jsonToGetUrl(body), {}, callback, errorCallback);
};

export const requestGetMediaById = (id, callback) => {
  // request("get", `${url.movie}?token=${getToken()}&id=${id}`, {}, callback);
  request("get", `${url.movie}}?id=${id}`, {}, callback);
};

export const requestDeleteMediaFile = (id, callback) => {
  showLoading();
  request("delete", url.movie, { id }, callback);
};

// * * * * * * *  S E A S O N S  * * * * * * *
export const requestAddOrUpdateSeason = (type, season, callback) => {
  showLoading();
  let file = new FormData();
  file.append("name", season.name);
  file.append("description", season.description);

  file.append("number", season.number);
  file.append("trailer_source", season.trailer_source);

  if (type === "put") file.append("id", season.id);
  if (type === "post") file.append("movieId", season.movieId);
  if (season.release_date) file.append("release_date", season.release_date);

  if (typeof season.poster === "object") file.append("poster", season.poster);

  request(type, `${url.movie}/season?token=${getToken()}`, file, callback);
};

export const requestDeleteSeason = (id, callback) => {
  showLoading();
  request("delete", url.movie + "/season", { id }, callback);
};

// * * * * * * *  S E R I E  * * * * * * *
export const requestAddOrUpdateSerie = (type, body, callback) => {
  showLoading();

  request(type, `${episodeUrl}?token=${getToken()}`, body, callback);
};

export const requestDeleteSerie = (id, callback) => {
  showLoading();
  request("delete", episodeUrl, { id }, callback);
};

// * * * * * * *  COLLECTIONS  * * * * * * *

const requests = {
  // a
  archive: {
    get: (callback) => {
      request("post", `${url.archive}get?append=all`, {}, callback);
    },

    add: (body, callback) => {
      showLoading();
      request("post", `${url.archive}add`, body, callback);
    },

    edit: (body, callback) => {
      showLoading();
      request("post", `${url.archive}update`, body, callback);
    },

    delete: (id, callback) => {
      showLoading();
      request("post", `${url.archive}delete`, { id }, callback);
    },

    server: {
      get: (callback) => {
        request("post", `${url.archive}get`, {}, callback);
      },
    },

    channel: {
      get: (archiverId, callback) => {
        request("post", `${url.archive}/archivedChannels`, { archiverId }, callback);
      },

      delete: (id, callback) => {
        showLoading();
        request(
          "post",
          `${url.archive}/removeArchivedChannel`,
          { channelId: id },
          callback
        );
      },
    },
  },

  // b

  brand_colors: {
    get: (callback, errorCallback) => {
      request("get", url.brand_colors, {}, callback, errorCallback);
    },

    edit: (body, callback, errorCallback) => {
      request("put", url.brand_colors, body, callback, errorCallback);
    },
  },

  // c
  chat: {
    conversation: {
      get: (callback, errorCallback) => {
        request(
          "get",
          `${
            url.conversation
          }?token=${getToken()}&query={"sort": ["last_message_date", "DESC"]}`,

          {},
          callback,
          errorCallback
        );
      },

      delete: (conversation_id, callback, errorCallback) => {
        request("delete", url.conversation, { conversation_id }, callback, errorCallback);
      },
    },

    quick_messages: {
      get: (callback, errorCallback) => {
        request(
          "get",
          `${url.quick_messages}?token=${getToken()}`,
          {},
          callback,
          errorCallback
        );
      },

      add: (body, callback, errorCallback) => {
        request("post", `${url.quick_messages}`, body, callback, errorCallback);
      },

      edit: (body, callback, errorCallback) => {
        request("put", `${url.quick_messages}`, body, callback, errorCallback);
      },

      delete: (id, callback) => {
        request("delete", `${url.quick_messages}`, { id }, callback);
      },
    },
  },

  countries: {
    get: (callback) => {
      request("get", `${url.movie}/country?token=${getToken()}`, {}, callback);
    },
  },

  channels: {
    all: (callback) => {
      request("post", `${url.channels}all`, {}, callback);
    },

    get: (body, callback) => {
      request("post", `${url.channels}get`, body, callback);
    },
  },

  content_provider: {
    get: (query, callback) => {
      query.token = getToken();
      request("get", `${url.content_provider}${jsonToGetUrl(query)}`, {}, callback);
    },

    add: (body, callback) => {
      showLoading();
      request("post", `${url.content_provider}`, body, callback);
    },

    edit: (body, callback) => {
      showLoading();
      request("put", `${url.content_provider}`, body, callback);
    },

    delete: (id, callback) => {
      request("delete", `${url.content_provider}`, { id }, callback);
    },

    login: (body, callback, errorCallback) => {
      request("post", url.content_provider_login, body, callback, errorCallback);
    },

    tops: (providerToken, callback) => {
      request(
        "get",
        `${url.tops}${jsonToGetUrl({ token: providerToken })}`,
        {},
        callback
      );
    },

    watched_list: (providerToken, callback, errorCallback) => {
      request(
        "get",
        `${url.watched_list}${jsonToGetUrl({ token: providerToken })}`,
        {},
        callback,
        errorCallback
      );
    },

    profile: (token, callback) => {
      request(
        "get",
        `${url.content_provider_profile}${jsonToGetUrl({ token })}`,
        {},
        callback
      );
    },
  },
  //d

  // e
  epg: {
    get: (callback) => {
      request("post", `${url.epg}get`, {}, callback);
    },

    getByDate: (id, date, callback) => {
      request("post", url.epgDate, { id, date, tz: "+4" }, callback);
    },
  },
  // f
  // g
  // h
  // i

  // j
  // k

  // l
  languages: {
    get: (query, callback) => {
      request("get", `${url.languages}${jsonToGetUrl(query)}`, {}, callback);
    },
  },

  // m
  monitoring: {
    get: (_url, ids, callback) => {
      let _ids = ids.join(",");

      request("get", `${_url}?id=${_ids}`, {}, callback);
    },
  },
  movie_country: (callback, errorCallback) => {
    request("get", `${url.movie_country}?token=${getToken()}`, callback, errorCallback);
  },
  // n
  // o
  // p
  payments: {
    monthly: (callback) => {
      request("post", `${url.payment}monthly`, {}, callback);
    },

    history: (body, callback, errorCallback) => {
      request("post", `${url.payment}payments`, body, callback, errorCallback);
    },
  },

  permission_menu: (callback, errorCallback) => {
    request("get", `${url.permission_menu}?token=${getToken()}`, callback, errorCallback);
  },
  // q
  // r

  // s

  // t

  // u
  // v

  vod: {
    statistics: {
      totals: (callback) => {
        request("get", `${url.movie_statistics}totals?token=${getToken()}`, {}, callback);
      },

      nowWatching: (callback) => {
        request(
          "get",
          `${url.movie_statistics}now_watching?token=${getToken()}`,
          {},
          callback
        );
      },

      mostWatchedMovie: (callback) => {
        request(
          "get",
          `${url.movie_statistics}most_watched_movie?token=${getToken()}`,
          {},
          callback
        );
      },

      mostWatchedList: (callback) => {
        request(
          "get",
          `${url.movie_statistics}most_watched_list?token=${getToken()}`,
          {},
          callback
        );
      },
    },

    movie_transcoder_host: (callback) => {
      request("get", `${url.configs}`, {}, callback);
    },

    genres: {
      get: (callback) => {
        request("get", `${url.movie}/genre?token=${getToken()}`, {}, callback);
      },

      addOrEdit: (type, data, callback) => {
        let formData = new FormData();

        formData.append("name", data.name);

        if (typeof data.image !== "string") {
          formData.append("icon", data.image);
        }

        if (type === "put") formData.append("id", data.id);

        request(type, `${url.movie}/genre?token=${getToken()}`, formData, callback);
      },

      delete: (id, callback) => {
        request("delete", url.movie + "/genre", { id }, callback);
      },
    },

    cast: {
      fromTmdb: (id, callback) => {
        request("get", `${actorForTmdbUrl}?token=${getToken()}&id=${id}`, {}, callback);
      },

      actorsFromTmdb: (name, callback) => {
        request(
          "get",
          `${actorForTmdbUrl}search?token=${getToken()}&query=${name}`,
          {},
          callback
        );
      },

      add: (actor, callback) => {
        showLoading();

        let newActor = new FormData();

        if (actor.tmdb_id) {
          newActor.append("tmdb_id", actor.tmdb_id);
        } else {
          newActor.append("name", actor.name);
          newActor.append("image", actor.image);
          newActor.append("bio", actor.bio);
          newActor.append("birthday", actor.birthday);
          newActor.append("gender", actor.gender.value);
          newActor.append("country", actor.country.value);
          newActor.append("role", actor.role);

          newActor.append("deathday", actor.deathday);
        }

        request("post", `${url.cast}?token=${getToken()}`, newActor, callback);
      },

      edit: (actor, callback) => {
        showLoading();

        let updatedActor = new FormData();
        updatedActor.append("id", actor.id);
        updatedActor.append("name", actor.name);
        updatedActor.append("bio", actor.bio);
        updatedActor.append("birthday", actor.birthday);
        updatedActor.append("gender", actor.gender.value);
        updatedActor.append("country", actor.country.value);
        updatedActor.append("role", actor.role);

        updatedActor.append("deathday", actor.deathday);
        if (actor.tmdb_id) updatedActor.append("tmdb_id", actor.tmdb_id);
        if (typeof actor.image === "object") updatedActor.append("image", actor.image);

        request("put", `${url.cast}?token=${getToken()}`, updatedActor, callback);
      },

      delete: (id, callback) => {
        showLoading();
        request("delete", url.cast, { id }, callback);
      },

      get: (body, callback) => {
        body.token = getToken();
        request("get", `${url.cast}${jsonToGetUrl(body)}`, body, callback);
      },

      galery: {
        add: (castId, galery, callback) => {
          showLoading();
          let newGalery = new FormData();
          newGalery.append("castId", castId);

          for (let i = 0; i < galery.length; i++) {
            newGalery.append("source", galery[i]);
          }

          request("post", `${url.cast}/galery?token=${getToken()}`, newGalery, callback);
        },

        delete: (id, callback) => {
          showLoading();
          request("delete", `${url.cast}/galery`, { id }, callback);
        },
      },

      fetcher: {
        state: (callback) => {
          request("get", `${url.cast_fetcher}/state?token=${getToken()}`, {}, callback);
        },

        fetch: (callback, errorCallback) => {
          request(
            "post",
            `${url.cast_fetcher}/fetch?token=${getToken()}`,
            { type: "tv_show" },
            callback,
            errorCallback
          );
        },
      },
    },

    media: {
      TARIFF: (id, callback) => {
        request(
          "get",
          `${url.movie}/tariff?movieId=${id}&token=${getToken()}`,
          {},
          callback
        );
      },

      getById: (id, callback) => {
        request("get", `${url.movie}?token=${getToken()}&id=${id}`, {}, callback);
      },

      translations: (id, model, callback) => {
        request("get", `${url.movie}/translations?id=${id}&model=${model}`, {}, callback);
      },

      translation: {
        add: (body, callback) => {
          request("post", `${url.movie}/translations`, body, callback);
        },

        edit: (body, callback) => {
          request("put", `${url.movie}/translations`, body, callback);
        },
      },

      cast: {
        add: (id, actors, callback) => {
          showLoading();
          let casts = new FormData();
          casts.append("id", id);
          casts.append("casts", JSON.stringify(actors));
          request("post", `${url.movie}/cast?token=${getToken()}`, casts, callback);
        },
      },

      addOrDeleteCast: (id, actors, callback) => {
        showLoading();
        let casts = new FormData();
        casts.append("id", id);
        casts.append("casts", JSON.stringify(actors));
        // request("put", `${url.movie}`, casts, callback);
      },

      galery: {
        add: (movieId, images, callback) => {
          showLoading();
          let galery = new FormData();
          galery.append("movieId", movieId);

          for (let i = 0; i < images.length; i++) {
            galery.append("source", images[i]);
          }

          request("post", `${url.movie}/galery?token=${getToken()}`, galery, callback);
        },

        delete: (id, callback) => {
          showLoading();
          request("delete", `${url.movie}/galery`, { id }, callback);
        },
      },

      soundtrack: {
        search: (name, callback, errorCallback) => {
          request(
            "get",
            `${url.soundtrack}youtube/search?token=${getToken()}&query=${name}`,
            {},
            callback,
            errorCallback
          );
        },

        download: (soundtrack, callback, errorCallback) => {
          showLoading();
          request(
            "post",
            `${url.soundtrack}download/youtube`,
            soundtrack,
            callback,
            errorCallback
          );
        },

        delete: (trackId, callback) => {
          showLoading();
          request("delete", `${url.sound}?token=${getToken()}`, { trackId }, callback);
        },
      },

      fetcher: {
        state: (callback) => {
          request("get", `${url.movie_fetcher}/state?token=${getToken()}`, {}, callback);
        },

        fetch: (callback, errorCallback) => {
          request(
            "post",
            `${url.movie_fetcher}/fetch?token=${getToken()}`,
            { type: "tv_show" },
            callback,
            errorCallback
          );
        },
      },

      transcode: {
        movie: (id, callback, errorCallback) => {
          request("post", url.transcode_movie, { id }, callback, errorCallback);
        },
      },

      edit: (formData, callback, errorCallback) => {
        showLoading();

        request(
          "put",
          `${url.movie}?token=${getToken()}`,

          formData,
          callback,
          errorCallback,
          null,
          false
        );
      },

      add: (formData, callback, errorCallback) => {
        showLoading();

        request("post", url.movie, formData, callback, errorCallback, null, false);
      },

      enable: (id, callback, errorCallback) => {
        request("put", `${url.movie}/enable`, { id }, callback, errorCallback);
      },

      disable: (id, callback, errorCallback) => {
        request("put", `${url.movie}/disable`, { id }, callback, errorCallback);
      },

      highlighted: (body, callback, errorCallback) => {
        request("post", `${url.movie}/highlighted`, body, callback, errorCallback);
      },

      notes: {
        get: (movieId, callback, errorCallback) => {
          request(
            "get",
            `${url.note}${jsonToGetUrl({ movieId, token: getToken() })}`,
            {},
            callback,
            errorCallback
          );
        },

        add: (body, callback, errorCallback) => {
          request("post", `${url.note}`, body, callback, errorCallback);
        },

        edit: (body, callback, errorCallback) => {
          request("put", `${url.note}`, body, callback, errorCallback);
        },

        delete: (id, callback, errorCallback) => {
          request("delete", `${url.note}`, { id }, callback, errorCallback);
        },
      },
    },
  },
  // w

  // x
  // y
  // z
};

export default requests;
