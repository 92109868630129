import { useEffect, useState } from "react";
import { Button, Drawer, Form, Input, InputNumber, notification, Select } from "antd";

import icons from "../../../config/icons";
import REQUESTS from "../../../api/requests";

import UserDrawer from "./UserDrawer";

import ButtonComponent from "../../../_components/button/ButtonComponent";

export default function LoginDrawer({ open, onClose, getData }) {
    const [isLoading, setIsLoading] = useState(false);

    const [isOpenNewUserDrawer, setIsOpenNewUserDrawer] = useState(false);

    const [codeIsVerified, setCodeIsVerified] = useState(false);

    const [usersOptions, setUsersOptions] = useState([]);

    const [form] = Form.useForm();

    const getUsersOptions = () => {
        REQUESTS.USERS.USERS.SEARCH().then((response) => {
            if (!response.error) {
                const data = response.message?.map((item) => {
                    return {
                        label: `${item.username} (Email: ${item.email})`,
                        value: item.id,

                        ...item,
                    };
                });

                setUsersOptions(data);
            }
        });
    };

    const onFinish = (values) => {
        setIsLoading(true);
        REQUESTS.USERS.USERS.ACTIVATE_LOGIN_CODE({ code: values.code, user_id: values.user_id })
            .then((response) => {
                setIsLoading(false);

                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });

                    return;
                }

                notification.success({
                    message: "Success",
                    description: "Login code is activated",
                });

                onClose();
                getData();
            })
            .catch((error) => {
                setIsLoading(false);

                notification.error({
                    message: "Error",
                    description: error,
                });
            });
    };

    useEffect(() => {
        if (open) {
            form.resetFields();
            setIsLoading(false);
            setCodeIsVerified(false);

            getUsersOptions();
        }
    }, [open]);

    const onValidateCode = () => {
        const { code } = form.getFieldsValue();

        form.setFieldsValue({
            user_id: undefined,
        });

        REQUESTS.USERS.USERS.VERIFY_LOGIN_CODE({ code })
            .then((response) => {
                if (!response.error) {
                    setCodeIsVerified(true);
                } else {
                    setCodeIsVerified(false);

                    form.setFields([
                        {
                            name: "code",
                            errors: ["Code is not valid"],
                        },
                    ]);
                }
            })
            .catch((error) => {
                setCodeIsVerified(false);
            });
    };

    const automaticlySelectUser = (newUser) => {
        if (newUser) {
            getUsersOptions();

            form.setFieldsValue({
                user_id: newUser.id,
            });
        }
    };

    return (
        <Drawer
            width={500}
            open={open}
            placement="right"
            onClose={onClose}
            title="Login with admin code"
        >
            <Form
                form={form}
                name="user"
                layout="vertical"
                onFinish={onFinish}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        form.submit();
                    }
                }}
                autoComplete="false"
            >
                <Form.Item
                    label="Code"
                    name="code"
                    rules={[
                        {
                            required: true,
                            message: "Please input code",
                        },
                    ]}
                >
                    <InputNumber
                        onChange={onValidateCode}
                        controls={false}
                        style={{ width: "100%" }}
                    />
                </Form.Item>

                <Form.Item
                    label="User"
                    name="user_id"
                    rules={[
                        {
                            required: true,
                            message: "Please select user",
                        },
                    ]}
                >
                    <Select
                        options={usersOptions}
                        disabled={!codeIsVerified}
                        optionFilterProp="children"
                        showSearch
                        notFoundContent={
                            <Button
                                icon={icons.ADD}
                                type="text"
                                style={{ width: "100%", textAlign: "center" }}
                                onClick={() => setIsOpenNewUserDrawer(true)}
                            >
                                Add New User
                            </Button>
                        }
                        filterOption={(input, option) => {
                            return (
                                (option?.label?.toLowerCase() ?? "").includes(
                                    input?.toLowerCase()
                                ) ||
                                (option?.phone ?? "").includes(input) ||
                                (option?.email?.toLowerCase() ?? "").includes(
                                    input?.toLowerCase()
                                ) ||
                                (option?.username?.toLowerCase() ?? "").includes(
                                    input?.toLowerCase()
                                )
                            );
                        }}
                        filterSort={(optionA, optionB) => {
                            return (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                    (optionB?.label ?? "").toLowerCase() ||
                                        (optionA?.email ?? "")
                                            .toLowerCase()
                                            .localeCompare((optionB?.email ?? "").toLowerCase()) ||
                                        (optionA?.username ?? "")
                                            .toLowerCase()
                                            .localeCompare(
                                                (optionB?.username ?? "").toLowerCase()
                                            ) ||
                                        (optionA?.phone ?? "")
                                            .toLowerCase()
                                            .localeCompare((optionB?.phone ?? "").toLowerCase())
                                );
                        }}
                    />
                </Form.Item>

                <Form.Item shouldUpdate noStyle>
                    {() => {
                        const { code, user_id } = form.getFieldsValue();

                        let isDisabled = !codeIsVerified || !code || !user_id;

                        return (
                            <Form.Item style={{ textAlign: "center" }}>
                                <ButtonComponent
                                    title="Login"
                                    icon={icons.LOGIN}
                                    disabled={isDisabled}
                                    isLoading={isLoading}
                                    onClick={() => form.submit()}
                                />
                            </Form.Item>
                        );
                    }}
                </Form.Item>
            </Form>

            <UserDrawer
                open={isOpenNewUserDrawer}
                getData={getData}
                onClose={() => {
                    setIsOpenNewUserDrawer(false);
                }}
                automaticlySelectUser={automaticlySelectUser}
            />
        </Drawer>
    );
}
