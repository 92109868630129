import { Drawer, Tabs } from "antd";

import { useEffect, useState } from "react";

import GaleriesTab from "./components/GaleriesTab";

import InformaitonTab from "./components/InformationTab";

export default function CastDrawer({ onClose, open, editable, getData }) {
    const [activeTab, setActiveTab] = useState("informaiton");

    const closeDrawer = () => {
        onClose();
        getData();
    };

    const tabs = [
        {
            label: "Informaiton",
            key: "informaiton",
            children: (
                <InformaitonTab
                    editable={editable}
                    getData={getData}
                    isOpen={open}
                    close={closeDrawer}
                />
            ),
            forceRender: true,
        },

        {
            label: "Galeries",
            key: "galeries",
            children: <GaleriesTab getData={getData} editable={editable} />,
            disabled: editable ? false : true,
        },
    ];

    useEffect(() => {
        if (!open) setActiveTab("informaiton");
    }, [open]);

    return (
        <Drawer
            width={700}
            title={`${editable ? "Edit" : "Add"} cast`}
            placement="right"
            onClose={onClose}
            open={open}
        >
            <Tabs
                tabPosition="top"
                items={tabs}
                activeKey={activeTab}
                onChange={setActiveTab}
            />
        </Drawer>
    );
}
