import { useEffect, useState } from "react";

import { useNavigate } from "react-router";

import { Dropdown, Menu, Modal } from "antd";

import { DownOutlined, QuestionOutlined } from "@ant-design/icons";

import { rValidateToken } from "../../../../server/requests";

import * as actions from "../../../../redux-store/actions";

import style from "./profile.module.scss";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";

import profileImage from "../../../../img/user.png";

export default function Profile({}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { setProfile } = bindActionCreators(actions, dispatch);

    const [adminInfo, setAdminInfo] = useState();

    const validate = () => {
        rValidateToken((data) => {
            let user = data.user;

            setProfile(user);

            localStorage.setItem("username", user.name + " " + user.surname);

            setAdminInfo(user);
        });
    };

    useEffect(() => {
        validate();
    }, []);

    const logout = () => {
        localStorage.clear();

        navigate("/");
    };

    const showConfirm = () => {
        Modal.confirm({
            title: "Do you Want to logout?",
            icon: <QuestionOutlined />,
            content: "",
            okText: "Logout",
            onOk() {
                logout();
            },
        });
    };

    const errorImage = (event) => {
        event.target.src = profileImage;
    };

    return (
        <Dropdown
            trigger={["click"]}
            overlay={
                <Menu style={{ marginTop: -10 }}>
                    <Menu.Item key="2" onClick={showConfirm}>
                        Logout
                    </Menu.Item>
                </Menu>
            }
        >
            <div className={style["profile"]}>
                <img
                    alt="User"
                    src={adminInfo?.image}
                    onError={errorImage}
                    // style={image_style}
                />

                {`${adminInfo ? adminInfo?.name + " " + adminInfo?.surname : ""}`}

                <DownOutlined />
            </div>
        </Dropdown>
    );
}
