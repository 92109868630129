export const setProfile = (item) => {
    return (dispatch) => {
        dispatch({
            type: "profile",
            payload: item,
        });
    };
};

export const setDashboardSettings = (settings) => {
    return (dispatch) => {
        dispatch({
            type: "dashboardSettings",
            payload: settings,
        });
    };
};

export const setPermissions = (item) => {
    return (dispatch) => {
        dispatch({
            type: "permissions",
            payload: item,
        });
    };
};

export const setUploadingListInRedux = (video) => {
    return (dispatch) => {
        dispatch({
            type: "uploading_list",
            payload: video,
        });
    };
};

export const setShowMenuItem = (item) => {
    return (dispatch) => {
        dispatch({
            type: "setShowMenuItem",
            payload: item,
        });
    };
};

export const setNewMessage = (item) => {
    return (dispatch) => {
        dispatch({
            type: "new_message",
            payload: item,
        });
    };
};

export const setIsChangedNotReadMessagesCount = (bool) => {
    return (dispatch) => {
        dispatch({
            type: "isChangedNotReadMessagesCount",
            payload: bool,
        });
    };
};
