import { useEffect, useState } from "react";

import { Drawer, Form, InputNumber, notification, Select, Space } from "antd";

import REQUESTS from "../../../../api/requests";

import { useCatchUpServersOptions } from "../../../../hooks/selectOptions";

import ButtonComponent from "../../../../_components/ButtonComponent";

import LiveTvSearch from "../../../../_components/searches/LiveTvSearch";

export default function AddToArchiveDrawer({ onClose, open, transcodingChannel }) {
    const serverOptions = useCatchUpServersOptions();

    const [isLoading, setIsLoading] = useState(false);

    const [selectedChannel, setSelectedChannel] = useState(null);

    const [resolutionsOptions, setResolutionsOptions] = useState([]);

    useEffect(() => {
        if (transcodingChannel) {
            const list = transcodingChannel.resolutions.map((item) => ({
                value: item.id,
                label: item.name,
                ...item,
            }));

            setResolutionsOptions(list);
        }
    }, [transcodingChannel]);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setIsLoading(true);

        const formData = new FormData();

        formData.append("id", values.channelId);
        formData.append("has_archive", true);

        const resolution = resolutionsOptions.find((item) => item.id === values.resolution);

        let _archivedChannel = {
            id: selectedChannel.archivedChannel ? selectedChannel.archivedChannel.id : "",
            url: resolution.info.output,
            archiverId: values.server,
            archiveDays: values.day,
            type: "inorain",
        };

        formData.append("archivedChannels", JSON.stringify(_archivedChannel));

        REQUESTS.TRANSCODERS.TRANSCODING.ADD_TO_ARCHIVE(formData)
            .then((response) => {
                setIsLoading(false);

                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });

                    return;
                }

                notification.success({
                    message: "Success",
                    description: "Archive added",
                });
                onClose();
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (!open) {
            form.resetFields();
            setIsLoading(false);
        }
    }, [open]);

    return (
        <Drawer width={400} title="Add to archive" placement="right" onClose={onClose} open={open}>
            <Form
                form={form}
                name="add-to-archive"
                layout="vertical"
                onFinish={onFinish}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        form.submit();
                    }
                }}
            >
                <Form.Item
                    label="Channels"
                    name="channelId"
                    rules={[
                        {
                            required: true,
                            message: "Please select channel",
                        },
                    ]}
                >
                    <LiveTvSearch onSelect={setSelectedChannel} />
                </Form.Item>

                <Space>
                    <Form.Item
                        label="Day"
                        name="day"
                        rules={[
                            {
                                required: true,
                                message: "Please input day",
                            },
                        ]}
                    >
                        <InputNumber style={{ width: 125 }} controls={false} />
                    </Form.Item>

                    <Form.Item
                        label="Server "
                        name="server"
                        rules={[
                            {
                                required: true,
                                message: "Please select server",
                            },
                        ]}
                    >
                        <Select options={serverOptions} style={{ width: 220 }} />
                    </Form.Item>
                </Space>

                <Form.Item
                    label="Resolution"
                    name="resolution"
                    rules={[
                        {
                            required: true,
                            message: "Please select resolution",
                        },
                    ]}
                >
                    <Select options={resolutionsOptions} />
                </Form.Item>

                <Form.Item style={{ textAlign: "center" }}>
                    <ButtonComponent
                        title="Save"
                        actionType="save"
                        isLoading={isLoading}
                        onClick={() => form.submit()}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
}
