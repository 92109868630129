import { useState, useEffect } from "react";
import ChartComponent from "../../../../../_components/chart/ChartComponent";
import { Area } from "@ant-design/plots";
import REQUESTS from "../../../../../api/requests";
import styles from "../../../styles/_charts.module.scss";
import { Empty } from "antd";

const PaymentCountChart = () => {
  const [isEmpty, setIsEmpty] = useState(false);

  const [selectedData, setSelectedData] = useState([
    {
      id: 0,
      name: "All time",
      query: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
        type: "year",
        value: -1,
      },
      active: false,
    },
    {
      id: 1,
      name: "Year",
      query: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
        type: "month",
        value: -1,
      },
      active: false,
    },
    {
      id: 2,
      name: "Month",
      query: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
        type: "day",
        value: -1,
      },
      active: true,
    },
  ]);

  const [paymentData, setPaymentData] = useState({
    data: [],
    xField: "date",
    yField: "value",
    label: {},
    point: {
      size: 5,
      shape: "circle",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  });

  const getPaymentCount = (query) => {
    const params = JSON.stringify(query);

    REQUESTS.DASHBOARD.PAYMENT_COUNT({ query: params })
      .then((response) => {
        if (!response.error) {
          setPaymentData({
            ...paymentData,
            data: response?.message?.data,
          });
        }
      })
      .catch((err) => {
        console.log(`err`, err);
      });
  };

  useEffect(() => {
    getPaymentCount(selectedData[2].query);
  }, []);

  useEffect(() => {
    !paymentData.data.length ? setIsEmpty(true) : setIsEmpty(false);
  }, [paymentData]);

  const handleSelectTab = (value) => {
    const selectActive = selectedData.filter((item) => {
      if (item.name === value.name) {
        return {
          ...item,
          active: true,
        };
      } else {
        return {
          ...item,
          active: false,
        };
      }
    });

    setSelectedData(selectActive);
    getPaymentCount(value.query);
  };

  return (
    <div className={styles["chart-wrapper"]}>
      <ChartComponent
        title="Payment count"
        children={isEmpty ? <Empty style={{ height: 400 }} /> : <Area {...paymentData} />}
        radioGroup={selectedData}
        selectedTab={(item) => handleSelectTab(item)}
      />
    </div>
  );
};

export default PaymentCountChart;
