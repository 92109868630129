import { Divider, Input, Select } from "antd";
import Container from "../../../../../_components/container/Container";
import { useLanguagesOptions } from "../../../../../hooks/selectOptions";
import { useEffect, useState } from "react";
import REQUESTS from "../../../../../api/requests";
import selectFromTmdb from "../../logic/selectFromTmdb";

import styles from "./_details.module.scss";
import { getAtLocal } from "../../../../../helpers/storages";

export default function SearchFromTmdb({ form }) {
  const languagesOptions = useLanguagesOptions();

  const [search, setSearch] = useState("");

  const [languageId, setLanguageId] = useState(13);

  const [searchResults, setSearchResults] = useState([]);

  const [selected, setSelected] = useState(null);

  const [defaultLanguage, setDefaultLanguage] = useState(null);
  const [mediaType, setMediaType] = useState("");

  const selectMedia = (item) => {
    REQUESTS.VOD.MEDIA_CONTENT.TMDB.SELECT(item?.media_type, item.id, languageId)
      .then((res) => {
        if (res.error) {
          return;
        }

        const data = res.message;

        selectFromTmdb(data, form);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSearch = () => {
    if (search?.length >= 3) {
      const searchValue = search.trim();
      REQUESTS.VOD.MEDIA_CONTENT.TMDB.SEARCH(searchValue, languageId)
        .then((res) => {
          if (res.error) {
            return;
          }

          const data = res.message;

          let newList = [];

          for (let i = 0; i < data.length; i++) {
            if (data[i].media_type == "movie" && mediaType === "movie") {
              newList.push(data[i]);
            } else if (data[i].media_type == "tv" && mediaType === "tv_show") {
              newList.push(data[i]);
            }
          }

          const parseSearchData = (data) => {
            let movieName = "";
            if (data.media_type === "movie") {
              movieName = data?.title;
            } else if (data.media_type === "tv") {
              movieName = data?.name;
            }

            if (data.media_type == "movie") {
              return `${movieName} (${data?.release_date?.split("-")[0] || ""})`;
            } else {
              return movieName;
            }
          };

          const _data = newList.map((item) => {
            return {
              value: item.id,
              label: parseSearchData(item),

              ...item,
            };
          });

          setSearchResults((prev) => [...prev, ..._data]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      onSearch();
      setSearchResults([]);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [search, languageId]);

  useEffect(() => {
    const selectedItem = searchResults.find((item) => item.value === selected);

    if (selectedItem) {
      selectMedia(selectedItem);
    }
  }, [selected]);

  useEffect(() => {
    const getLanguage = languagesOptions?.find((item) => item?.iso_code === "en");

    setMediaType(getAtLocal("mediaType") || "movie");

    setDefaultLanguage(getLanguage || "English");
  }, []);

  return (
    <Container>
      <Divider orientation="left" plain>
        <b>Search from Tmdb</b>
      </Divider>

      <div className={styles["search-from-tmdb"]}>
        <Select
          options={languagesOptions}
          onChange={(e) => {
            // setSelected(e);
            setLanguageId(e);
          }}
          optionLabelProp="label"
          value={languageId}
          showSearch
          filterOption={(input, option) =>
            (option?.label?.toLowerCase() ?? "").includes(input.toLocaleLowerCase())
          }
          filterSort={(optionA, optionB) => {
            return (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase());
          }}
        />

        <Select
          options={searchResults}
          onChange={(value) => {
            setSelected(value);
          }}
          optionLabelProp="label"
          onSearch={setSearch}
          showSearch
          filterOption={(input, option) =>
            (option?.label?.toLowerCase() ?? "").includes(input.toLocaleLowerCase())
          }
          filterSort={(optionA, optionB) => {
            return (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase());
          }}
        />
      </div>
    </Container>
  );
}
