import { Select } from "antd";

import { useEffect, useState } from "react";

import REQUESTS from "../../../../api/requests";

import IMAGES from "../../../../config/images";

import { returnGender } from "../../../../config/config";

import styles from "../_cast.module.scss";

export default function CastFromTmdb({ onSelect }) {
    const [search, setSearch] = useState("");
    const [selected, setSelected] = useState(null);

    const [list, setList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const onSearch = () => {
        setList([]);
        setIsLoading(true);

        let body = {
            query: search,
        };

        REQUESTS.VOD.CAST.TMDB.SEARCH(body)
            .then((response) => {
                setIsLoading(false);
                if (response.error) {
                    return;
                }

                const list = response.message.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                        poster: `https://image.tmdb.org/t/p/original/${item.profile_path?.original}`,

                        ...item,
                    };
                });

                setList(list);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        let timeout = setTimeout(() => {
            if (search.length > 3) {
                onSearch();
            } else {
                setList([]);
            }
        }, [500]);

        return () => {
            clearTimeout(timeout);
        };
    }, [search]);

    return (
        <Select
            style={{ width: "100%" }}
            showSearch
            mode={"default"}
            value={selected}
            onChange={setSelected}
            loading={isLoading}
            showArrow={false}
            optionLabelProp="label"
            filterOption={(input, option) => (option?.label?.toLowerCase() ?? "").includes(input)}
            filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            onSearch={setSearch}
            placeholder="Select an option"
        >
            {list.map((item) => (
                <Select.Option key={item.value} value={item.value} label={item.label}>
                    <div className={styles["cast"]} onClick={() => onSelect(item)}>
                        <img src={item.poster} onError={(e) => (e.target.src = IMAGES.USER)} />

                        <div>
                            <b>{item.label}</b>

                            <div>
                                <span className={styles["info"]}>Gender:</span>
                                <span>{returnGender(item.gender)}</span>
                            </div>

                            <div>
                                <span className={styles["info"]}>Profession:</span>
                                <span>{item.known_for_department}</span>
                            </div>
                        </div>
                    </div>
                </Select.Option>
            ))}
        </Select>
    );
}
