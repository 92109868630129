import { useEffect, useState } from "react";

import { Checkbox, Drawer, Form, Input, notification } from "antd";

import REQUESTS from "../../../api/requests";

import ButtonComponent from "../../../_components/ButtonComponent";

export default function VodContentProvidersDrawer({ onClose, open, editable, getData }) {
    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setIsLoading(true);

        let body = {
            company_name: values.company_name,
            contact_person: values.contact_person,
            phone_number: values.phone_number,
            email: values.email,
            status: values.status ? "active" : "inactive",
        };

        if (values.password) {
            body.password = values.password;
        }

        if (editable) {
            body.id = editable.id;

            REQUESTS.VOD.CONTENT_PROVIDERS.EDIT(body)
                .then((response) => {
                    setIsLoading(false);

                    if (response.error) {
                        notification.error({
                            message: "Error",
                            description: response.message,
                        });

                        return;
                    }

                    getData();
                    onClose();
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        } else {
            REQUESTS.VOD.CONTENT_PROVIDERS.ADD(body)
                .then((response) => {
                    setIsLoading(false);

                    if (response.error) {
                        notification.error({
                            message: "Error",
                            description: response.message,
                        });

                        return;
                    }
                    getData();
                    onClose();
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        if (!open) {
            form.resetFields();

            setIsLoading(false);
        }

        if (editable) {
            form.setFields([
                { name: "company_name", value: editable.company_name },
                { name: "contact_person", value: editable.contact_person },
                { name: "phone_number", value: editable.phone_number },
                { name: "email", value: editable.email },
                { name: "status", value: editable.status === "active" ? true : false },
            ]);
        }
    }, [open, editable]);

    return (
        <Drawer
            title={`${editable ? "Edit" : "Add"} provider`}
            placement="right"
            onClose={onClose}
            open={open}
        >
            <Form
                form={form}
                name="vod-content-provider"
                layout="vertical"
                onFinish={onFinish}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        form.submit();
                    }
                }}
            >
                <Form.Item
                    label="Company name"
                    name="company_name"
                    rules={[
                        {
                            required: true,
                            message: "Please input company name",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Contact person"
                    name="contact_person"
                    rules={[
                        {
                            required: true,
                            message: "Please input contact person",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item label="Phone number" name="phone_number">
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Please input email",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: !editable,
                            message: "Please input password",
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item noStyle valuePropName="checked" name="status">
                    <Checkbox>Active</Checkbox>
                </Form.Item>

                <Form.Item style={{ textAlign: "center" }}>
                    <ButtonComponent
                        title="Save"
                        actionType="save"
                        isLoading={isLoading}
                        onClick={() => form.submit()}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
}
