import request from "../../request";

const host = `${process.env.REACT_APP_API_HOST || "DEFAULT_REACT_API_HOST"}admin/`;

const URL = {
  INFO: `${host}info`,
};

export default {
  GET: (query) => request("GET", URL.INFO, query),

  EDIT: (query) => request("PUT", URL.INFO, query),
};
