import { Empty, Image } from "antd";

import { useEffect, useState } from "react";

import REQUESTS from "../../../../../api/requests";

import styles from "./most_watched.module.scss";

export default function NowWatched() {
  const [data, setData] = useState([]);

  const getList = () => {
    REQUESTS.DASHBOARD.VOD.NOW_WATCHING()
      .then((response) => {
        if (!response.error) {
          let list = [];

          for (let i = 0; i < response?.message?.length; i++) {
            list.push(response?.message[i]);
          }

          setData(list);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className={styles["now-watched-list"]}>
      {data.length > 0 ? (
        data.map((movie, index) => {
          return (
            <div className={styles["now-watching"]} key={index}>
              <div className={styles["poster"]}>
                <Image
                  src={movie.movie?.poster}
                  style={{
                    width: 35,
                    height: 35,
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              </div>

              <div className={styles["name"]}>
                <b style={{ fontSize: 12 }}>{movie.movie?.name}</b>
              </div>

              <div className={styles["type"]}>
                <span style={{ color: "grey" }}>
                  {movie.movie?.type === "tv_show" ? "Tv Show" : "Movie"}
                </span>
              </div>
            </div>
          );
        })
      ) : (
        <div className={styles["empty-block"]}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </div>
  );
}
