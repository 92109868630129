import { useEffect, useState } from "react";

import { Form, Input, notification, Switch } from "antd";

import REQUESTS from "../../../../api/requests";

import ImageUpload from "../../../../components/ImageUpload";

import ButtonComponent from "../../../../_components/button/ButtonComponent";

export default function PaypalConfig({ data, selectedCurrency }) {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [icon, setIcon] = useState({
    url: null,
    file: null,
  });

  const onFinish = (values) => {
    setIsLoading(true);

    const formData = new FormData();

    console.log("icon", icon);

    if (icon.file) {
      formData.append("paypal_icon", icon.file);
    }

    formData.append("id", data.id);
    formData.append("currency_id", selectedCurrency);
    formData.append("paypal_enabled", values.paypal_enabled);
    formData.append("paypal_client_id", values.paypal_client_id);
    formData.append("paypal_client_secret", values.paypal_client_secret);
    formData.append("paypal_webhook_id", values.paypal_webhook_id);

    console.log("Success:", values);

    REQUESTS.PAYMENTS.CONFIGS.UPDATE(formData)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message,
          });

          return;
        }

        notification.success({
          message: "Success",
          description: "Paypal configs is changed",
        });
      })
      .catch((error) => {
        setIsLoading(false);
        notification.error({
          message: "Error",
          description: error,
        });
      });
  };

  useEffect(() => {
    if (data) {
      setIcon({
        url: data.paypal_icon,
        file: null,
      });

      form.setFields([
        { name: "paypal_icon", value: data.paypal_icon },
        { name: "paypal_enabled", value: data.paypal_enabled },
        { name: "paypal_client_id", value: data.paypal_client_id },
        { name: "paypal_client_secret", value: data.paypal_client_secret },
        { name: "paypal_webhook_id", value: data.paypal_webhook_id },
      ]);
    }
  }, [data]);

  const onChangeIcon = (value) => {
    setIcon(value);

    form.setFieldsValue({
      paypal_icon: value.file,
    });
  };

  return (
    <Form
      form={form}
      name="paypal-config"
      layout="vertical"
      onFinish={onFinish}
      style={{ maxWidth: 800 }}
    >
      <Form.Item shouldUpdate noStyle>
        {() => {
          const { paypal_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label="Icon"
              name="paypal_icon"
              rules={[
                {
                  required: paypal_enabled,
                  message: "Please choose icon",
                },
              ]}
            >
              <ImageUpload image={icon} setImage={onChangeIcon} />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item label="Enabled" name="paypal_enabled" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { paypal_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label="Paypal client id"
              name="paypal_client_id"
              rules={[
                {
                  required: paypal_enabled,
                  message: "Please enter paypal client id",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { paypal_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label="Paypal client secret"
              name="paypal_client_secret"
              rules={[
                {
                  required: paypal_enabled,
                  message: "Please enter paypal client id",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { paypal_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label="Paypal webhook id"
              name="paypal_webhook_id"
              rules={[
                {
                  required: paypal_enabled,
                  message: "Please enter paypal webhook id",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        }}
      </Form.Item>

      <ButtonComponent
        title="Save"
        actionType="save"
        isLoading={isLoading}
        onClick={() => form.submit()}
      />
    </Form>
  );
}
