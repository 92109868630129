import { useState } from "react";

import { notification } from "antd";

import REQUESTS from "../../../api/requests";

import LauncherDevicesTable from "./LauncherDevicesTable";
import PageComponent from "../../../_components/page/PageComponent";

export default function LauncherDevicesPage({}) {
    const [getAgain, setGetAgain] = useState(false);

    const getDataSource = (query, onSuccess, onError) => {
        REQUESTS.LAUNCHER.DEVICES.GET({ query: JSON.stringify(query) })
            .then((response) => {
                onSuccess(response.message);
            })
            .catch((error) => {
                onError(error);
            });
    };

    const onChangeBlock = (item) => {
        const query = {
            id: item.id,
            is_blocked: !item.is_blocked,
        };

        REQUESTS.LAUNCHER.DEVICES.BLOCK(query)
            .then((response) => {
                if (!response.error) {
                    notification.success({
                        message: "Success",
                        description: response.message,
                    });
                    setGetAgain((prev) => !prev);
                }
            })
            .catch((error) => {
                notification.error({
                    message: "Error",
                    description: error,
                });
            });
    };

    return (
        <PageComponent routes={["Launcher", "Devices"]}>
            <LauncherDevicesTable
                onChange={onChangeBlock}
                getAgain={getAgain}
                getDataSource={getDataSource}
            />
        </PageComponent>
    );
}
