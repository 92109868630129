import request from "../../api/request";
import { DEMO_API_HOST } from "../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
    LOGIN: `${host}content_provider/login`,

    CONTENT_PROVIDER: `${host}admin/content_provider`,
};

const contentProviderApi = {
    GET: (query) => request("GET", URL.CONTENT_PROVIDER, query),

    LOGIN: (query) => request("POST", URL.LOGIN, query),
};

export default contentProviderApi;
