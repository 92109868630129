import { Tabs } from "antd";
import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../../redux-store/actions";

import REQUESTS from "../../../api/requests";

import configDashboardSettings from "../../../config/configDashboardSettings";

import ContentTab from "./ContentTab";
import NotificationTab from "./NotificationTab";
import PrimaryColorTab from "./PrimaryColorTab";
import AdminPanelLogoTab from "./AdminPanelLogo";
import GeolocationApiTab from "./GeolocationApiTab";
import AdminPanelFaviconTab from "./AdminPanelFaviconTab";
import ScheduledRestartTab from "./ScheduledRestartTab";

import PageComponent from "../../../_components/page/PageComponent";

export default function GeneralSettings({}) {
    const dispatch = useDispatch();

    const { setDashboardSettings } = bindActionCreators(actions, dispatch);

    const [settings, setSettings] = useState([]);

    const getSettings = () => {
        REQUESTS.SETTINGS.GENERAL.GET().then((response) => {
            if (!response.error) {
                setSettings(response.message);
            }
        });
    };

    const getDashboardSettings = () => {
        REQUESTS.SETTINGS.GENERAL.GET().then((response) => {
            if (!response.error) {
                const dashboardSettins = configDashboardSettings(response.message);

                setDashboardSettings(dashboardSettins);
            }
        });
    };

    useEffect(() => {
        getSettings();
    }, []);

    const returnTab = (item) => {
        switch (item.key) {
            case "stream_restart":
                return <ScheduledRestartTab data={item} />;

            case "notification":
                return <NotificationTab data={item} />;

            case "primary_color":
                return <PrimaryColorTab data={item} getDashboardSettings={getDashboardSettings} />;

            case "admin_panel_logo":
                return (
                    <AdminPanelLogoTab data={item} getDashboardSettings={getDashboardSettings} />
                );

            case "admin_panel_favicon":
                return (
                    <AdminPanelFaviconTab data={item} getDashboardSettings={getDashboardSettings} />
                );

            case "geolocation_api":
                return <GeolocationApiTab data={item} />;

            case "show_content_type":
                return <ContentTab data={item} />;

                return;
        }
    };

    return (
        <PageComponent routes={["Settings", "General settings"]}>
            <Tabs
                tabPosition="left"
                tabBarStyle={{ background: "white" }}
                items={settings.map((_, i) => {
                    return {
                        label: _.title,
                        key: _.key,
                        children: returnTab(_),
                    };
                })}
            />
        </PageComponent>
    );
}
