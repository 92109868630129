import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
    LESSONS: `${host}admin/lessons`,
};

const lessonsApi = {
    GET: (query) => request("GET", URL.LESSONS, query),

    ADD: (query) => request("POST", URL.LESSONS, query),

    EDIT: (query) => request("PUT", URL.LESSONS, query),

    DELETE: (query) => request("DELETE", URL.LESSONS, query),
};

export default lessonsApi;
