import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox, Drawer, Form, Input, notification, Space } from "antd";

import REQUESTS from "../../../../api/requests";

import Scheduling from "./Scheduling";

import Payment from "./Payment";
import Content from "./content/Content";
import svodConfirm from "./svodConfirm";
import createSubscriptionData from "./createSubscriptionData";
import initEditableSubscriptionData from "./initEditableSubscriptionData";

import ButtonComponent from "../../../../_components/ButtonComponent";

import styles from "./payment-subscription.module.scss";

const defaultVodService = {
  year: {
    type: "include",
    values: []
  },
  id: {
    exclude: [],
    include: []
  },
  category: {
    type: "include",
    values: []
  }
};

const defaultLiveTvService = {
  id: {
    exclude: [],
    include: []
  },
  category: {
    type: "include",
    values: []
  }
};

export default function PaymentSubscriptionDrawer({
  onClose,
  open,
  editable,
  getData
}) {
  const { permissions } = useSelector((state) => state.globalState);

  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const [vodService, setVodSeervice] = useState(defaultVodService);

  const [liveTvService, setLiveTvSeervice] = useState(defaultLiveTvService);

  const onConfirmSvodRequest = () => {
    const values = form.getFieldsValue();

    addOrEdit(values, true);
  };

  const addOrEdit = (values, force) => {
    setIsLoading(true);

    const body = createSubscriptionData(
      values,
      vodService,
      liveTvService,
      editable,
      force,
      permissions
    );

    if (editable) {
      REQUESTS.PAYMENTS.SUBSCRIPTIONS.EDIT(body)
        .then((response) => {
          setIsLoading(false);
          if (response.error) {
            if (response?.message?.type) {
              if (response.message.type === "force") {
                svodConfirm(response.message.message, onConfirmSvodRequest);
                return;
              }
            }
            notification.error({
              description: response.message
            });
            return;
          }
          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      REQUESTS.PAYMENTS.SUBSCRIPTIONS.ADD(body)
        .then((response) => {
          setIsLoading(false);

          if (response?.message?.type) {
            if (response.message.type === "force") {
              svodConfirm(response.message.message, onConfirmSvodRequest);
              return;
            }
          }

          if (response.error) {
            notification.error({
              description: response.message
            });
            return;
          }
          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const onFinish = (values) => {
    addOrEdit(values, false);
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setIsLoading(false);
      setVodSeervice(defaultVodService);
      setLiveTvSeervice(defaultLiveTvService);
    }

    if (open) {
      if (editable) {
        initEditableSubscriptionData(
          form,
          editable,
          setVodSeervice,
          setLiveTvSeervice,
          permissions
        );
      }
    }
  }, [open, editable]);

  return (
    <Drawer
      title={`${editable ? "Edit" : "Add"} subscription`}
      placement="right"
      onClose={onClose}
      open={open}
      width={900}
    >
      {open && (
        <Form
          form={form}
          name="payment-group"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            scheduling_type: "day",
            payment_type: "prepaid"
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              form.submit();
            }
          }}
        >
          <div className={styles["row"]}>
            <div style={{ width: "50%" }}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input name"
                  }
                ]}
              >
                <Input />
              </Form.Item>

              <Payment permissions={permissions} />

              <Space>
                <Form.Item
                  name="archive_service"
                  noStyle
                  valuePropName="checked"
                >
                  <Checkbox>Has archive</Checkbox>
                </Form.Item>

                {permissions?.payments?.authorize && (
                  <Form.Item
                    name="is_supported_authorize"
                    noStyle
                    valuePropName="checked"
                  >
                    <Checkbox>Authorize</Checkbox>
                  </Form.Item>
                )}
              </Space>
            </div>

            <div style={{ width: "50%" }}>
              <Scheduling form={form} />

              <Form.Item label="Description" name="description">
                <Input.TextArea rows={6} />
              </Form.Item>

              <Content
                editable={editable}
                vodService={vodService}
                permissions={permissions}
                setVodSeervice={setVodSeervice}
                liveTvService={liveTvService}
                setLiveTvSeervice={setLiveTvSeervice}
              />
            </div>
          </div>

          <Form.Item style={{ textAlign: "center", marginTop: 50 }}>
            <ButtonComponent
              title="Save"
              actionType="save"
              isLoading={isLoading}
              onClick={() => form.submit()}
            />
          </Form.Item>
        </Form>
      )}
    </Drawer>
  );
}
