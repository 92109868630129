import { useEffect, useState } from "react";
import { DatePicker, Divider, Form, InputNumber, Radio, Select, Switch } from "antd";

import { useCountriesOptions } from "../../../../../hooks/selectOptions";

import Container from "../../../../../_components/container/Container";

import styles from "./_restriction_monetization.module.scss";

export default function Restriction({ form }) {
  const countriesOptions = useCountriesOptions("name", "name");

  const [geolocationRestriction, setGeolocationRestriction] = useState(false);

  const handleSwitchChange = (value) => {
    setGeolocationRestriction(value);

    form.setFieldsValue({ is_location_restriction: value });
  };

  useEffect(() => {
    setGeolocationRestriction(form?.getFieldValue("is_location_restriction"));
  }, [form?.getFieldValue("is_location_restriction")]);

  return (
    <Container className={styles["restriction"]}>
      <Divider orientation="left" plain>
        <b>Restriction</b>
      </Divider>

      <Form.Item label="Is protected" name="is_protected">
        <Radio.Group name="radiogroup" defaultValue={false}>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>

      <div className={styles["age-date"]}>
        <Form.Item label="Age restriction" name="pg">
          <InputNumber style={{ width: 100, whiteSpace: "nowrap" }} controls={false} />
        </Form.Item>

        <Form.Item label="License end date" name="license_ending_date">
          <DatePicker style={{ width: "100%" }} showTime={false} format={"YYYY-MM-DD"} />
        </Form.Item>
      </div>

      <Form.Item name="is_location_restriction" valuePropName="checked">
        <div style={{ width: "100%", display: "flex", gap: 10 }}>
          <span>Geolocation restriction</span>
          <Switch checked={geolocationRestriction} onChange={handleSwitchChange} />
        </div>
      </Form.Item>

      {geolocationRestriction && (
        <Form.Item
          label="Available countries"
          name="available_countries"
          rules={[
            {
              required: true,
              message: "Countries field is required",
            },
          ]}
        >
          <Select
            mode="multiple"
            showSearch={true}
            options={countriesOptions}
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(input?.toLocaleLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
          />
        </Form.Item>
      )}
    </Container>
  );
}
