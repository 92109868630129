import { useEffect, useState } from "react";

import { Drawer, Form, Input, InputNumber, notification } from "antd";

import REQUESTS from "../../../api/requests";

import LessonsSelect from "./LessonsSelect";

import ButtonComponent from "../../../_components/ButtonComponent";

export default function LessonsDrawer({ onClose, open, editable, getData }) {
    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setIsLoading(true);

        const body = {
            name: values.name,
            unit: values.unit,
            movies_id: values.movies_id.join(","),
        };

        if (editable) {
            body.id = editable.id;

            REQUESTS.LESSONS.LESSONS.EDIT(body)
                .then((response) => {
                    setIsLoading(false);

                    if (response.error) {
                        notification.error({
                            description: response.message,
                        });

                        return;
                    }

                    getData();
                    onClose();
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        } else {
            REQUESTS.LESSONS.LESSONS.ADD(body)
                .then((response) => {
                    setIsLoading(false);

                    if (response.error) {
                        notification.error({
                            description: response.message,
                        });

                        return;
                    }
                    getData();
                    onClose();
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        if (!open) {
            form.resetFields();
        }
        if (editable) {
            form.setFields([
                { name: "name", value: editable.name },
                { name: "unit", value: editable.unit },
            ]);
        }
    }, [open, editable]);

    return (
        <Drawer
            title={`${editable ? "Edit" : "Add"} lesson`}
            placement="right"
            onClose={onClose}
            open={open}
        >
            <Form
                form={form}
                name="lesson"
                layout="vertical"
                onFinish={onFinish}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        form.submit();
                    }
                }}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please input name",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Unit"
                    name="unit"
                    rules={[
                        {
                            required: true,
                            message: "Please input unit",
                        },
                    ]}
                >
                    <InputNumber controls={false} style={{ width: "100%" }} />
                </Form.Item>

                <LessonsSelect form={form} editable={editable} open={open} />

                <Form.Item style={{ textAlign: "center" }}>
                    <ButtonComponent
                        title="Save"
                        actionType="save"
                        isLoading={isLoading}
                        onClick={() => form.submit()}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
}
