// import requests from "../../../server/requests";
import { DEMO_API_HOST } from "../../../config/statics";
import request from "../../../api/request";

const host = ` ${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URLS = {
  BRAND_COLORS_GET: `${host}v2/admin/app_color`,
  BRAND_COLORS_UPDATE: `${host}v2/admin/app_color`,
};

const brandColorsApi = {
  GET: (query) => request("get", URLS.BRAND_COLORS_GET, query),

  UPDATE: (query) => request("put", URLS.BRAND_COLORS_UPDATE, query),
};

export default brandColorsApi;
