import { useEffect, useState } from "react";

import { Drawer, Form, Input, notification } from "antd";

import REQUESTS from "../../../../../../api/requests";
import ButtonComponent from "../../../../../../_components/button/ButtonComponent";

export default function EditableMovieDrawer({ onClose, open, getData, editable }) {
    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setIsLoading(true);

        const body = {
            name: values.name,
            url: values.url,
            id: editable?.id,
        };

        REQUESTS.USERS.USER.PLAYLISTS.ROWS.EDIT(body)
            .then((response) => {
                setIsLoading(false);

                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });

                    return;
                }

                getData();

                onClose();
            })
            .catch((err) => {
                notification.error({
                    message: "Error",
                    description: err,
                });

                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (!open) {
            form.resetFields();
        } else {
            form.setFields([
                { name: "name", value: editable.name },
                { name: "url", value: editable.url },
            ]);
        }
    }, [open, editable]);

    return (
        <Drawer width={500} open={open} placement="right" onClose={onClose} title="Add playlist">
            <Form
                form={form}
                name="user-playlist"
                layout="vertical"
                onFinish={onFinish}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        form.submit();
                    }
                }}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please input name",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Url"
                    name="url"
                    rules={[
                        {
                            required: true,
                            message: "Please input url",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item style={{ textAlign: "center" }}>
                    <ButtonComponent
                        title="Save"
                        actionType="save"
                        isLoading={isLoading}
                        onClick={() => form.submit()}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
}
