import { useState, useEffect } from "react";
import ChartComponent from "../../../../../../_components/chart/ChartComponent";
import { Pie } from "@ant-design/plots";
import REQUESTS from "../../../../../../api/requests";
import styles from "../../../../styles/_charts.module.scss";
import { Empty } from "antd";

const GroupUserChart = () => {
  const [isEmpty, setIsEmpty] = useState(false);
  const [groupUserData, setGroupUserData] = useState({
    appendPadding: 10,
    data: [],
    angleField: "value",
    colorField: "name",
    label: {
      type: "inner",
      content: " ",
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
  });

  const getGroupUser = () => {
    REQUESTS.DASHBOARD.USERS_GROUPS()
      .then((response) => {
        if (!response.error) {
          setGroupUserData({
            ...groupUserData,
            data: response?.message?.data,
          });
        }
      })
      .catch((err) => {
        console.log(`err`, err);
      });
  };

  useEffect(() => {
    getGroupUser();
  }, []);

  useEffect(() => {
    !groupUserData.data.length ? setIsEmpty(true) : setIsEmpty(false);
  }, [groupUserData]);

  return (
    <div className={styles["chart-wrapper"]}>
      <ChartComponent
        title="Group Users"
        children={
          isEmpty ? <Empty style={{ height: 400 }} /> : <Pie {...groupUserData} />
        }
      />
    </div>
  );
};

export default GroupUserChart;
