import { useEffect, useState } from "react";

import { Checkbox, Drawer, Form, Input, notification, Progress, Radio } from "antd";
import ButtonComponent from "../../../../_components/button/ButtonComponent";
import InputField from "./InputField";
import OutputField from "./OutputField";
import REQUESTS from "../../../../api/requests";
import { useSelector } from "react-redux";

export default function VodTranscodingDrawer({ onClose, open, getData }) {
  const [isLoading, setIsLoading] = useState(false);

  const [progress, setProgress] = useState(0);
  const [addType, setAddType] = useState(false);
  const [showOutput, setShowOutput] = useState(false);

  const [form] = Form.useForm();
  const { permissions } = useSelector((state) => state.globalState);

  const onFinish = (values) => {
    console.log(values.choose_file);
    const body = {
      name: values.name,
      //   output: values.output,
      is_public: addType,
    };

    if (showOutput) {
      body.output = values.output;
    }

    switch (values.input_type) {
      case "select_file":
        body.input = values.select_file;
        body.input_type = "file";

        break;

      case "choose_file":
        body.input = values?.choose_file;
        body.input_type = "file";

        break;

      case "url":
        body.input = values.url;
        body.input_type = "url";

        break;

      default:
        body.input = "";
        break;
    }

    REQUESTS.VOD.TRANSCODING.ADD(body)
      .then((response) => {
        setIsLoading(false);
        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message,
          });
          return;
        }
        getData();
        onClose();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.message,
        });

        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  return (
    <Drawer title="Transcode" placement="right" onClose={onClose} open={open} width={600}>
      <Form
        form={form}
        name="vod-transcode"
        layout="vertical"
        onFinish={onFinish}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            form.submit();
          }
        }}
        initialValues={{
          input_type: "select_file",
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name",
            },
          ]}
        >
          <Input />
        </Form.Item>

        {open && <InputField form={form} setProgress={setProgress} progress={progress} />}

        {showOutput && <OutputField form={form} />}

        {progress ? <Progress percent={progress} size="small" width={"100%"} /> : ""}

        <Checkbox
          name="is_public"
          onChange={(e) => setAddType(e.target.checked)}
          style={{ marginBottom: 20 }}
        >
          Make video public
        </Checkbox>

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title="Save"
            actionType="save"
            disabled={progress}
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
