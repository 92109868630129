import request from "../../../api/request";

const host = `${process.env.REACT_APP_API_HOST}`;

const url = {
  COLLECTION_MOVIES: `${host}v2/admin/collections`,

  COLLECTION_MOVIE_POSITIONS: `${host}v2/admin/collections`,
};

const collectionPageApi = {
  COLLECTION_MOVE: (query) => request("POST", `${url.COLLECTION_MOVIES}/movies`, query),

  COLLECTION_MOVE_GET: (query) =>
    request("GET", `${url.COLLECTION_MOVIES}/movies`, query),

  COLLECTION_MOVE_DELETE: (query) =>
    request("DELETE", `${url.COLLECTION_MOVIES}/movies`, query),

  COLLECTION_MOVIE_SEARCH: (query) =>
    request("GET", `${url.COLLECTION_MOVIES}/movies/not_exit`, query),

  COLLECTION_POSITION: (query) =>
    request("PUT", `${url.COLLECTION_MOVIE_POSITIONS}/movies/positions`, query),
};

export default collectionPageApi;
