import { useEffect, useState } from "react";

import icons from "../../../../../config/icons";
import PATHS from "../../../../../config/paths";

import REQUESTS from "../../../../../api/requests";

import StatisticCard from "./StatisticCard";

import styles from "./statistics.module.scss";
import { useSelector } from "react-redux";

export default function StatisticsList({ liveTvStatistic, usersStatistic }) {
  const { permissions } = useSelector((state) => state.globalState);

  const [list, setList] = useState([]);

  const getPaymentStatistic = () => {
    REQUESTS.DASHBOARD.PAYMENTS().then((response) => {
      if (!response.error) {
        setList((prev) => {
          return prev.map((item) => {
            if (item.routeId === "payemntHistoryId") {
            }
            return item;
          });
        });
      }
    });
  };

  useEffect(() => {
    const listArr = [
      {
        path: PATHS.PAYMENT_SUBSCRIPTION,
        routeId: "tariffId",
        icon: icons.SUBSCRIPTION,
        title: "Subscriptions",
        count: "0",
      },

      {
        path: PATHS.PAYMENT_HISTORY,
        routeId: "payemntHistoryId",
        icon: icons.PAYMENTS,
        title: "Payments",
        count: "0",
      },

      {
        path: PATHS.USERS,
        routeId: "usersId",
        icon: icons.USERS_GROUP,
        title: "Users",
        count: "0",
      },

      {
        path: PATHS.USERS,
        routeId: "onlineId",
        icon: icons.ONLINE,
        title: "Online",
        count: "0",
      },
    ];
    if (permissions?.LIVE_TV) {
      listArr.unshift({
        path: PATHS.LIVE_TV,
        routeId: "channelsId",
        icon: icons.TV,
        title: "Live TV",
        count: "0",
      });
    }

    setList(listArr);

    getPaymentStatistic();
  }, []);

  useEffect(() => {
    if (liveTvStatistic) {
      setList((prev) => {
        return prev.map((item) => {
          if (item.routeId === "channelsId") {
            item.count = liveTvStatistic.total;
          }
          return item;
        });
      });
    }
  }, [liveTvStatistic]);

  useEffect(() => {
    if (usersStatistic) {
      let newList = [...list];

      for (let i = 0; i < newList.length; i++) {
        if (newList[i].routeId === "usersId") {
          newList[i].count = usersStatistic.total || "0";
        }

        if (newList[i].routeId === "onlineId") {
          newList[i].count = usersStatistic.online || "0";
        }
      }

      setList(newList);
    }
  }, [usersStatistic]);

  return (
    <div className={styles["statistics-list"]}>
      {list.map((item, index) => {
        return <StatisticCard key={index} item={item} />;
      })}
    </div>
  );
}
