import request from "../../../../api/request";
import { DEMO_API_HOST } from "../../../../config/statics";
import { getToken } from "../../../../server/requests";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
  ADS: `${host}admin/advertisement/movie`,

  SVOD: `${host}movie/tariff`,

  MOVIE: `${host}movie`,

  TRANSLATIONS: `${host}movie/translations`,

  TMDB: `${host}test/tmdb/`,

  NOTE: `${host}movie/not/translations`,

  BADGES: `${host}v2/admin/movies/badges`,

  // GENRES: `${host}/genre`

  // https://api.inorain.tv/admin/advertisement/movie?movie_id=1
};

const mediaApi = {
  ADD: (body) => request("POST", URL.MOVIE, body),

  // advertisement
  ADVERTISEMENT: {
    AVOD: (body) => request("GET", `${URL.ADS}?movie_id=${body}`),

    SVOD: (body) => request("GET", `${URL.SVOD}?movieId=${body}`),
  },

  GENRES: {
    GET: (cb) => request("GET", `${URL.MOVIE}/genre`, cb),
  },

  EDIT: (body) => request("PUT", URL.MOVIE, body),

  GET: (id) => request("GET", `${URL.MOVIE}?token=${getToken()}&id=${id}`),

  TMDB: {
    SEARCH: (name, language_id) =>
      request(
        "GET",
        `${URL.TMDB}serach?token=${getToken()}&q=${name}&language_id=${language_id}`
      ),

    SELECT: (type, id, language_id) =>
      request(
        "GET",
        `${
          URL.TMDB
        }${type}info?token=${getToken()}&tmdbId=${id}&language_id=${language_id}`
      ),
  },

  TRANSLATIONS: {
    GET: (id, model) =>
      request("GET", `${URL.TRANSLATIONS}?token=${getToken()}&id=${id}&model=${model}`),

    ADD: (data) => request("POST", URL.TRANSLATIONS, data),

    EDIT: (data) => request("PUT", URL.TRANSLATIONS, data),
  },

  NOTES: {
    GET: (query) => request("GET", URL.NOTE, query),

    ADD: (query) => request("POST", URL.NOTE, query),

    EDIT: (query) => request("PUT", URL.NOTE, query),

    DELETE: (query) => request("DELETE", URL.NOTE, query),
  },

  BADGES: {
    GET: (query) => request("GET", URL.BADGES, query),
  },
};

export default mediaApi;
