import { useState, useEffect } from "react";
import { Button, Table } from "antd";
import icons from "../../config/icons";
import TableConfigPopup from "./TableConfigPopup";
import ButtonComponent from "../button/ButtonComponent";
import { useLocation } from "react-router";
import FilterOutlined from "@ant-design/icons/lib/icons/FilterOutlined";
import TableLocaleComponent from "./TableLocaleComponent";

export default function TableComponent({
  columns,
  dataSource,
  onChange,
  isLoading,
  pagination,
  rowKey = "id",
  header,
  localeClick,
  ...props
}) {
  const [showConfig, setShowConfig] = useState(false);

  const [tableColumns, setTableColumns] = useState(columns);

  const [getAgain, setAgain] = useState(false);

  const { pathname } = useLocation();
  const tableId = pathname?.split("/")[2];

  const handleTableChange = (pagination, filters, sorter) => {
    let data = {
      search: filters,
      page: pagination.current,
      limit: pagination.pageSize,
    };

    if (sorter.field) {
      let secondValue = undefined;

      if (sorter?.order == "ascend") {
        secondValue = "ASC";
      } else if (sorter?.order == "descend") {
        secondValue = "DESC";
      }

      if (secondValue) {
        data.sort = [sorter?.field, secondValue];
      } else {
        data.sort = undefined;
      }
    } else {
      data.sort = ["id", "DESC"];
    }

    onChange(data);
  };

  useEffect(() => {
    try {
      const configData = JSON.parse(localStorage?.getItem(`tableConfig_${tableId}`));

      if (!configData) return;

      const findColumn = columns?.map((column) => {
        return {
          ...column,
          visible: configData[column?.key],
        };
      });

      const newColumns = findColumn?.filter((column) => column?.visible);

      setTableColumns(newColumns);
    } catch (error) {
      console.log(error);
    }
  }, [getAgain, showConfig, columns]);

  return (
    <div>
      {header && (
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            // justifyContent: "space-between",
            // alignItems: "center",
            width: "100%",
            marginBottom: 15,
            gap: 10,
          }}
        >
          <ButtonComponent
            style={{
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              border: "0.1px solid #eee",
            }}
            type="secondary"
            onClick={() => setShowConfig(true)}
            icon={<FilterOutlined />}
          />
          {header}
        </div>
      )}

      <Table
        rowKey={rowKey}
        size="small"
        bordered
        columns={tableColumns}
        dataSource={dataSource}
        onChange={handleTableChange}
        loading={isLoading}
        pagination={
          pagination
            ? {
                position: ["bottomCenter"],
                showSizeChanger: true,
                pageSize: pagination.limit || 10,
                current: pagination.page || 1,
                ...pagination,
              }
            : false
        }
        {...props}
        locale={{
          emptyText: localeClick && (
            <TableLocaleComponent
              click={() => {
                localeClick();
              }}
            />
          ),
        }}
      />
      <TableConfigPopup
        open={showConfig}
        onClose={() => setShowConfig(false)}
        columns={columns}
        tableId={tableId || ""}
        getAgain={setAgain}
      />
    </div>
  );
}
