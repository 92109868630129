import { useState, useEffect } from "react";
import { Select, notification, Button, Skeleton, Empty } from "antd";

import REQUESTS from "../../../api/requests";
import { requestGetMediasList } from "../../../server/requests";

import style from "./slider.module.scss";

export default function VodTab({ onClose, open }) {
    const [search, setSearch] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [movies, setMovies] = useState([]);

    const [selected, setSelected] = useState(null);

    const getMoves = () => {
        setSearchLoading(true);

        let body = {
            limit: 50,
            enabled: true,
            search: JSON.stringify({ name: search }),
        };

        requestGetMediasList(
            body,
            (data) => {
                const list = data.rows.map((item) => ({
                    value: item.id,
                    label: item.name,

                    ...item,
                }));
                setMovies(list);

                setSearchLoading(false);
            },
            () => {
                setSearchLoading(false);
            }
        );
    };

    const selectMovie = (item) => {
        setSelected(item);
        setIsOpen(false);
    };

    const save = () => {
        setIsLoading(true);

        REQUESTS.WEB_PAGE.SLIDER.ADD({ type: "movie", id: selected?.id })
            .then((response) => {
                setIsLoading(false);

                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });

                    return;
                }

                notification.success({
                    message: "Success",
                    description: response.message,
                });

                onClose();
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        let timeout = setTimeout(() => {
            getMoves();
        }, [500]);

        if (search.length) {
            setIsOpen(true);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [search]);

    useEffect(() => {
        if (open) {
            setSearch("");
            setIsOpen(false);
            setMovies([]);
            setSelected(null);
            getMoves();
        } else {
            setIsOpen(false);
        }
    }, [open]);

    return (
        <div>
            <Select
                showSearch
                style={{
                    width: "100%",
                }}
                placeholder="Search movie and select"
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? "").includes(input)}
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                onFocus={() => setIsOpen(true)}
                onBlur={() => {
                    setTimeout(() => {
                        setIsOpen(false);
                    }, 500);
                }}
                onSearch={setSearch}
                searchValue={search}
                value={selected}
                open={isOpen}
                dropdownRender={(a) => {
                    return (
                        <div className={style["search-container"]}>
                            {searchLoading ? (
                                <Skeleton active />
                            ) : movies.length ? (
                                movies.map((item) => {
                                    return (
                                        <div
                                            className={style["movie-channel"]}
                                            key={item.id}
                                            onClick={() => selectMovie(item)}
                                        >
                                            <img src={item.poster} />
                                            <div>
                                                <p className={style["name"]}>
                                                    {item.name}{" "}
                                                    {item.year ? `( ${item.year} )` : ""}
                                                </p>
                                                <p className={style["type"]}>{item.type}</p>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <Empty />
                            )}
                        </div>
                    );
                }}
            />

            <Button
                type="primary"
                onClick={save}
                disabled={!selected}
                loading={isLoading}
                style={{ width: "100%", marginTop: 20 }}
            >
                Save
            </Button>
        </div>
    );
}
