import request from "../../request";

const host = `${process.env.REACT_APP_API_HOST || "DEFAULT_REACT_API_HOST"}admin/`;

const URL = {
  SLIDER: `${host}slider`,
};

export default {
  GET: (query) => request("GET", URL.SLIDER, query),

  ADD: (query) => request("POST", URL.SLIDER, query),

  EDIT: (query) => request("PUT", URL.SLIDER, query),

  DELETE: (query) => request("DELETE", URL.SLIDER, query),
};
