import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
    SERVERS: `${host}v2/admin/streaming/server`,
};

const streamerServersApi = {
    GET: (query) => request("GET", URL.SERVERS, query),

    DELETE: (query) => request("DELETE", URL.SERVERS, query),
};

export default streamerServersApi;
