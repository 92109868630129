import request from "../../../api/request";

import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
    PLATFORMS: `${host}admin/platform`,
};

const webPlatformsApi = {
    GET: (query) => request("GET", URL.PLATFORMS, query),

    EDIT: (query) => request("PUT", URL.PLATFORMS, query),
};

export default webPlatformsApi;
