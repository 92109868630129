import { useState } from "react";
import {
  Button,
  Descriptions,
  Divider,
  Popconfirm,
  Space,
  Tooltip,
  Typography,
  notification,
} from "antd";
import { parseDateFormat } from "../../../../../config/config";

import styles from "../../user.module.scss";
import icons from "../../../../../config/icons";
import BalanceDrawer from "./BalanceDrawer";
import ButtonComponent from "../../../../../_components/button/ButtonComponent";
import UserDrawer from "../../../users/UserDrawer";
import REQUESTS from "../../../../../api/requests";
import { useSelector } from "react-redux";

export default function Biography({ user, getUser }) {
  const { permissions } = useSelector((state) => state.globalState);

  const [balanceType, setBalanceType] = useState(null);
  const [isOpenEditDrawer, setIsOpenEditDrawer] = useState(false);

  const onLock = () => {
    REQUESTS.USERS.USERS.LOCK({ ids: user.id })

      .then((response) => {
        if (!response.error) {
          notification.success({
            message: "Success",
            description: response.message,
          });
          getUser();
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error,
        });
      });
  };

  const onUlock = () => {
    REQUESTS.USERS.USERS.UNLOCK({ ids: user.id })
      .then((response) => {
        if (!response.error) {
          notification.success({
            message: "Success",
            description: response.message,
          });

          getUser();
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error,
        });
      });
  };

  const onArchive = () => {
    REQUESTS.USERS.USERS.ARCHIVE({ id: user.id })
      .then((response) => {
        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message,
          });
          return;
        }

        if (!response.error) {
          notification.success({
            message: "Success",
            description: response.message,
          });

          getUser();
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error,
        });
      });
  };

  const onRestore = () => {
    REQUESTS.USERS.USERS.RESTORE({ id: user.id })
      .then((response) => {
        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message.message,
          });
          return;
        }

        if (!response.error) {
          notification.success({
            message: "Success",
            description: response.message,
          });

          getUser();
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error,
        });
      });
  };
  return (
    <>
      <Descriptions
        title={
          <>
            <Space>
              <h3>
                {user.name} {user.surname}
              </h3>

              <ButtonComponent
                actionType="edit"
                title="Edit"
                onClick={() => setIsOpenEditDrawer(true)}
              />

              {user.status !== 0 ? (
                <Popconfirm
                  placement="topRight"
                  title="Do you want to unlock this user"
                  onConfirm={onUlock}
                  okText="Unlock"
                  cancelText="Cancel"
                >
                  <ButtonComponent actionType="unlock" title="Unlock" />
                </Popconfirm>
              ) : (
                <Popconfirm
                  placement="topRight"
                  title="Do you want to lock this user"
                  onConfirm={onLock}
                  okText="Lock"
                  cancelText="Cancel"
                >
                  <ButtonComponent actionType="lock" title="Lock" />
                </Popconfirm>
              )}

              {user.archive ? (
                <Popconfirm
                  placement="topRight"
                  title="Do you want to restore this user"
                  onConfirm={onRestore}
                  okText="Restore"
                  cancelText="Cancel"
                >
                  <ButtonComponent actionType="restore" title="Restore" />
                </Popconfirm>
              ) : (
                <Popconfirm
                  placement="topRight"
                  title="Do you want to archive this user"
                  onConfirm={onArchive}
                  okText="Archive"
                  cancelText="Cancel"
                >
                  <ButtonComponent actionType="archive" title="Archive" />
                </Popconfirm>
              )}
            </Space>

            <Divider />
          </>
        }
        column={4}
      >
        <Descriptions.Item label="Name">{user.name}</Descriptions.Item>
        <Descriptions.Item label="Surname">{user.surname}</Descriptions.Item>
        <Descriptions.Item label="Birthday">
          {parseDateFormat(user.birthday)}
        </Descriptions.Item>
        <Descriptions.Item label="Phone">{user.phone || "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
        <Descriptions.Item label="Country">{user.country || "N/A"}</Descriptions.Item>
        <Descriptions.Item label="City">{user?.city || "N/A"}</Descriptions.Item>

        <Descriptions.Item label="Parental code">{user.parental_code}</Descriptions.Item>

        <Descriptions.Item label="Register">
          {parseDateFormat(user.register_date)}
        </Descriptions.Item>
        <Descriptions.Item label="Tariff">
          {user.tariff ? (
            <Space>
              <Space>
                {user.tariff.name}
                <div className={styles["tariff"]}>
                  (<span>{user.tariff.price}</span>
                  <span>{user.tariff.currency}</span>)
                </div>
              </Space>
            </Space>
          ) : (
            "N/A"
          )}
        </Descriptions.Item>
        {permissions.billing && (
          <Descriptions.Item label="Expire tariff">
            {user.expire_tariff ? parseDateFormat(user.expire_tariff) : "N/A"}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Payment type">
          {user.payment_type || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label="Wallet address">
          {user?.wallet_address || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label="Subscribtion id">
          <Typography.Paragraph
            ellipsis={true}
            style={{ margin: 0, width: 115, textAlign: "start" }}
          >
            {user.subscription_id || "N/A"}
          </Typography.Paragraph>
        </Descriptions.Item>

        {permissions?.billing && !user?.is_guest && (
          <Descriptions.Item label="Balance">
            {" "}
            <Space className={styles["balance"]}>
              {user.balance}
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                type="primary"
                icon={icons.ADD}
                size={"small"}
                onClick={() => setBalanceType("Add")}
              />
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                type="primary"
                danger
                icon={icons.MINUS}
                size={"small"}
                onClick={() => setBalanceType("Minus")}
              />
            </Space>
          </Descriptions.Item>
        )}
        {/* <Descriptions.Item label="POINT">{user.point || "N/A"}</Descriptions.Item>
        <Descriptions.Item label="SPAY">{user.spay || "N/A"}</Descriptions.Item>
        <Descriptions.Item label="SCPT">{user.scpt || "N/A"}</Descriptions.Item> */}
        <Divider />
      </Descriptions>
      <BalanceDrawer
        userId={user?.id}
        type={balanceType}
        open={balanceType ? true : false}
        onClose={() => setBalanceType(null)}
        getData={getUser}
      />
      <UserDrawer
        open={isOpenEditDrawer}
        editable={user}
        getData={getUser}
        onClose={() => {
          setIsOpenEditDrawer(false);
        }}
      />
      {/* <Descriptions column={3}>
        <Descriptions.Item label="POINT">{user.point || "N/A"}</Descriptions.Item>
        <Descriptions.Item label="SPAY">{user.spay || "N/A"}</Descriptions.Item>
        <Descriptions.Item label="SCPT">{user.scpt || "N/A"}</Descriptions.Item>
      </Descriptions> */}
    </>
  );
}
