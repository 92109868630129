import { useEffect, useState } from "react";
import { Button, Popconfirm, Popover, Row, Space, Tag, Tooltip, Typography } from "antd";

import icons from "../../../../config/icons";

import TableButtons from "../../../../_components/table/TableButtons";

import TableComponent from "../../../../_components/table/TableComponent";

import ButtonComponent from "../../../../_components/button/ButtonComponent";
import TableEmpty from "../../../../_components/table/TableEmpty";
import { parseDate } from "../../../../config/config";

import { getColumnSearchProps } from "../../../../config/config";

import ChannelResolutions from "./ChannelResolutions";
import {
  getAtLocal,
  getAtSession,
  setInLocal,
  setInSession,
} from "../../../../helpers/storages";
import { asc_desc } from "../../../../helpers/ascDesc";

export default function Table({
  getAgain,
  openDrawer,
  getDataSource,
  handleMenuClick,
  setPlayingResolution,
  onPlayPause,
  getChannel,
}) {
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(getAtLocal("pageLimit_transcoders") || 10);

  const [currentPage, setCurrentPage] = useState(getAtSession("transcoders")?.page || 1);

  const [sort, setSort] = useState(getAtSession("transcoders")?.sort || ["id", "DESC"]);

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState(
    getAtSession("transcoders")?.search || {
      name: null,
      serverId: null,
    }
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [dataCounter, setDataCounter] = useState(0);

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      fixed: "left",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },
    {
      title: "Uptime",
      dataIndex: "uptime",
      key: "uptime",
      align: "center",
      width: 120,
      sorter: true,
      render: (text, record, index) => {
        return parseDate(record.up_time);
      },
    },
    {
      title: "Status",
      defaultSortOrder:
        getAtSession("transcoders")?.sort?.[0] === "status" &&
        asc_desc(getAtSession("transcoders")?.sort?.[1]),
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 120,
      sorter: true,
      render: (text, record, index) => {
        return (
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {record.status === 1 ? (
              <Tag color="green">Active</Tag>
            ) : (
              <Tooltip placement="topLeft" title={record?.reason}>
                <Tag color="volcano">Errored</Tag>
              </Tooltip>
            )}
            <Button
              icon={<i className="fa fa-terminal" />}
              onClick={() => {
                handleLogDrawer(record);
              }}
            />
          </Row>
        );
      },
    },

    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 100,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      defaultFilteredValue: getAtSession("transcoders")?.search?.name,
      filteredValue: getAtSession("transcoders")?.search?.name,

      ...getColumnSearchProps(),
    },

    {
      title: "Input",
      dataIndex: "input",
      key: "input",
      align: "center",
      width: 200,

      render: (text, record, index) => {
        const cutText = record?.input?.slice(-18);
        return record.input.length ? (
          <Typography.Paragraph
            style={{ margin: 0, width: 180, display: "flex" }}
            copyable={{
              text: record.input,
            }}
          >
            <div style={{ width: "100%" }}>...{cutText}</div>
          </Typography.Paragraph>
        ) : (
          <TableEmpty />
        );
      },
    },

    {
      title: "Resolutions",
      dataIndex: "resolutions",
      key: "resolutions",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        return record.resolutions.length ? (
          <Popover
            zIndex={1}
            content={() => (
              <ChannelResolutions
                resolutions={record.resolutions}
                play={(url) => setPlayingResolution(url)}
              />
            )}
            title={`${record.name} resolutions`}
            trigger="hover"
          >
            <Tag style={{ cursor: "pointer" }}>{record?.resolutions?.length}</Tag>
          </Popover>
        ) : (
          <TableEmpty />
        );
      },
    },
    {
      title: "Server name",
      dataIndex: "server",
      key: "server",
      align: "center",
      render: (text, record, index) => {
        return record.server ? record.server.name : <TableEmpty />;
      },
      width: 150,
    },

    {
      title: "Group name",
      dataIndex: "group",
      key: "group",
      align: "center",
      render: (text, record, index) => {
        return record.group ? record.group.name : <TableEmpty />;
      },
      width: 150,
    },

    {
      title: "Error count",
      dataIndex: "errorCount",
      key: "errorCount",
      align: "center",
      width: 100,
    },

    {
      title: "Updated date",
      defaultSortOrder:
        getAtSession("transcoders")?.sort?.[0] === "updated_at" &&
        asc_desc(getAtSession("transcoders")?.sort?.[1]),
      key: "updatedAt",
      dataIndex: `updatedAt`,
      align: "center",
      render: (text, record, index) => {
        return parseDate(record.updatedAt);
      },
      sorter: true,
    },

    {
      title: "Play / Pause",
      defaultSortOrder:
        getAtSession("transcoders")?.sort?.[0] === "is_paused" &&
        asc_desc(getAtSession("transcoders")?.sort?.[1]),
      dataIndex: "is_paused",
      key: "is_paused",
      align: "center",
      fixed: "right",
      width: 120,
      sorter: true,
      render: (text, record, index) => {
        const key = {
          key: record.is_paused ? "play" : "pause",
        };
        return (
          <Space>
            <ButtonComponent
              style={{
                marginTop: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={record.is_paused ? icons.PLAY : icons.PAUSE}
              danger={!record.is_paused}
              onClick={() => onPlayPause(key.key, record)}
            />
          </Space>
        );
      },
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: "Edit",
              icon: icons.EDIT,
            },

            {
              key: "add_to_archive",
              name: "Add to archive",
              icon: icons.ARCHIVE_ICON,
            },

            {
              key: "add_to_streaming",
              name: "Add to streaming",
              icon: icons.STREAMING,
            },

            {
              key: "delete",
              name: "Delete",
              icon: icons.DELETE,
              question: `Do you want to delete this transcoding?`,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    const transcoders = {
      page: data.page,
      search: data.search,
    };
    if (data.sort) {
      transcoders.sort = [`${data.sort[0]}`, `${data.sort[1]}`];
    }
    setInLocal("pageLimit_transcoders", data.limit);
    setInSession("transcoders", transcoders);

    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);
  };

  const handleLogDrawer = (record) => {
    getChannel(record);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleBulkAction = (action) => {
    const id = selectedRowKeys?.join(",");

    switch (action) {
      case "delete":
        handleMenuClick({ key: action }, { id });
        break;
      case "play":
      case "pause":
        onPlayPause(action, { id });
        break;

      default:
        return null;
    }
  };

  useEffect(() => {
    setDataCounter((prev) => prev + 1);

    const fetchLiveTvData = () => {
      if (dataCounter >= 1) {
        setLoading(false);
      } else {
        setLoading(true);
      }
      const query = {
        sort,
        limit,
        page: currentPage,
      };

      if (search.name) {
        query.search = {
          name: search.name,
        };
      }

      if (search.categories) {
        query.filter = {
          categoryId: search.categories,
        };
      }

      function onSuccess(data) {
        setLoading(false);
        setDataSource(data?.rows);
        setTotal(data?.count);

        const maxPage = Math.ceil(data?.count / limit);
        const storedPage = getAtSession("transcoders")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("transcoders", {
            ...getAtSession("transcoders"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("transcoders", {
            ...getAtSession("transcoders"),
            page: currentPage,
          });
        }
      }

      function onError(data) {
        setLoading(false);
      }
      getDataSource({ query: JSON.stringify(query) }, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      setDataCounter(dataCounter + 1);

      if (
        getAtSession("transcoders") &&
        getAtSession("transcoders")?.page > currentPage
      ) {
        setInSession("transcoders", {
          ...getAtSession("transcoders"),
          page: 1,
        });
        setCurrentPage(getAtSession("transcoders")?.page);
      }
      setShowConfirmModal(false);
      fetchLiveTvData();
    }, 500);

    return () => {
      clearTimeout(timeout);
      setDataCounter(0);
    };
  }, [limit, currentPage, sort, search, getAgain]);

  return (
    <TableComponent
      header={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <ButtonComponent
            icon={icons.ADD}
            title="New Transcoding"
            onClick={openDrawer}
          />
          {selectedRowKeys.length > 0 && (
            <div
              style={{ display: "flex", alignItems: "center", gap: 10, marginTop: 20 }}
            >
              <ButtonComponent
                type="default"
                title={"Play"}
                icon={icons.PLAY}
                onClick={() => {
                  handleBulkAction("play");
                }}
              />

              <ButtonComponent
                type="default"
                title={"Pause"}
                icon={icons.PAUSE}
                onClick={() => {
                  handleBulkAction("pause");
                }}
              />
              <>
                <ButtonComponent
                  type="default"
                  title={"Delete"}
                  icon={icons.DELETE}
                  onClick={() => {
                    setShowConfirmModal(true);
                  }}
                />
                <Popconfirm
                  open={showConfirmModal}
                  placement="topLeft"
                  title={"Do you want to delete selected transcoders?"}
                  onConfirm={() => handleBulkAction("delete")}
                  onCancel={() => {
                    setSelectedRowKeys([]);
                    setShowConfirmModal(false);
                  }}
                />
              </>
            </div>
          )}
        </div>
      }
      rowSelection={rowSelection}
      onChange={handleTableChange}
      isLoading={loading}
      dataSource={dataSource}
      columns={columns}
      localeClick={openDrawer}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
      scroll={{
        x: 1700,
      }}
    />
  );
}
