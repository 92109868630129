import adminsApi from "../pages/admins/_adminsApi";
import adsApi from "../pages/ads/_adsApi";

import userIconsApi from "../pages/application/user-icons/_userIconsApi";

import contentProviderApi from "../pages/content-provider/_contentProviderApi";

import epgApi from "../pages/epg/_epgApi";

import historyApi from "../pages/history/_historyApi";

import importApi from "../pages/import/_importApi";

import launcherAppsApi from "../pages/launcher/apps/_launcherAppsApi";
import launcherConfigsApi from "../pages/launcher/configs/_launcherConfigsApi";
import launcherDevicesApi from "../pages/launcher/devices/_launcherDevicesApi";
import launcherMessageApi from "../pages/launcher/messages/_launcherMessageApi";

import lessonsApi from "../pages/lessons/lessons/_lessonsApi";

import quizApi from "../pages/lessons/quiz/_quizApi";

import PROFILE from "./routes/profile";
import MESSAGES from "./routes/messages";
import ARCHIVE from "./routes/archive/archive";

import NEWS from "./routes/web-page/news";
import FAQ from "./routes/web-page/faq";
import SLIDER from "./routes/web-page/slider";
import WEB_SETTINGS from "./routes/web-page/settings";
import COUNTRIES from "./routes/general/countries";
import GENERAL from "./routes/general/general";
import TVOD_PAYMENT from "./routes/payments/tvod-payment";
import PAYMENTS_HISTORY from "./routes/payments/history";
import PAYMENTS_CONFIG from "./routes/payments/config";
import PAYMENTS_SUBSCRIBTIONS from "./routes/payments/subscriptions";

import WEB_HOOKS from "./routes/settings/web-hooks";
import KEYWORDS from "./routes/settings/keywords";
import SCRIPTS from "./routes/settings/scripts";

import STORE_LINKS from "./routes/settings/store-links";

import apksApi from "../pages/settings/apks/_apksAPi";
import vodGenresApi from "../pages/vod/genres/_vodGenresApi";
import basicInfoApi from "../pages/application/basic-info/_basicInfoApi";
import vodTranscodingApi from "../pages/vod/transcoding/vodTranscodingApi";
import catchUpServersApi from "../pages/catch-up/servers/_catchUpServersApi";
import ResolutionsApi from "../pages/transcoders/resolutions/ResolutionsApi";
import streamerServersApi from "../pages/streamer/servers/_streamerServersApi";
import streamerStreamsApi from "../pages/streamer/streams/_streamerStreamsApi";
import catchUpRecordingsApi from "../pages/catch-up/recordings/_catchUpRecordingsApi";
import generalSettingsApi from "../pages/settings/general-settings/_generalSettingsApi";
import vodContentProvidersApi from "../pages/vod/content-providers/vodContentProvidersApi";

import liveTvApi from "../pages/live-tv/live-tv/_liveTvApi";
import liveTvCategoriesApi from "../pages/live-tv/categories/_liveTvCategoriesApi";

import languagesApi from "../pages/application/languages/_languagesApi";
import translationsApi from "../pages/application/translations/_translationsApi";

import userApi from "../pages/users/user/_userApi";
import usersApi from "../pages/users/users/_usersApi";
import usersGroupsApi from "../pages/users/groups/_userGrpupsApi";

import TRANSCODERS_GROUPS from "../pages/transcoders/groups/TranscodersGroupsApi";
import transcodersServersApi from "../pages/transcoders/servers/_transcodersServersApi";
import vodCollectionsApi from "../pages/vod/collections/_vodCollectionsApi";
import castApi from "../pages/vod/cast/_castApi";
import transcoderTranscodingApi from "../pages/transcoders/transcoding/logic/api";
import dashboardApi from "../pages/dashboard/logic/api";
import mediasApi from "../pages/vod/medias/logic/api";

import mediaApi from "../pages/vod/media-content/logic/api";

import webFeaturesApi from "../pages/web-page/features/_webFeaturesApi";
import webPlatformsApi from "../pages/web-page/platforms/_webPlatformsApi";
import cdnAPi from "../pages/settings/cdn/logic/cdnApi";
import _webAvailableDevicesApi from "../pages/web-page/available-devices/_webAvailableDevicesApi";
import documentsApi from "../pages/settings/documents/_documentsApi";
import collectionPageApi from "../pages/vod/collection/_collectionPageAppi";
import brandColorsApi from "../pages/application/branding/_brandingApi";

const REQUESTS = {
  // a
  ADS: adsApi,
  ADMINS: adminsApi,
  ARCHIVE,

  CATCH_UP: {
    RECORDINGS: catchUpRecordingsApi,
    SERVERS: catchUpServersApi,
  },

  APPLICATION: {
    USER_ICONS: userIconsApi,
    BASIC_INFO: basicInfoApi,
    LANGUAGES: languagesApi,
    TRANSLATIONS: translationsApi,
  },
  // b
  BRAND_COLORS: brandColorsApi,
  // c
  CONTENT_PROVIDER: contentProviderApi,
  CURRENCY: GENERAL.CURRENCY,
  COUNTRIES,

  // d
  DASHBOARD: dashboardApi,
  DEVICES: GENERAL.DEVICES,
  // e
  EPG: epgApi,
  // f
  // g
  // h
  HISTORY: historyApi,
  // i
  IMPORT: importApi,
  // j
  // k
  KEYWORDS,
  // l
  LESSONS: {
    LESSONS: lessonsApi,
    QUIZ: quizApi,
  },

  LAUNCHER: {
    APPS: launcherAppsApi,
    DEVICES: launcherDevicesApi,
    CONFIGS: launcherConfigsApi,
    MESSAGES: launcherMessageApi,
  },

  LIVE_TV: {
    CHANNELS: liveTvApi,
    CATEGORIES: liveTvCategoriesApi,
  },
  // m
  MESSAGES,
  // n
  // o
  // p
  PROFILE,
  PAYMENTS: {
    TVOD_PAYMENT,
    HISTORY: PAYMENTS_HISTORY,
    CONFIGS: PAYMENTS_CONFIG,
    SUBSCRIPTIONS: PAYMENTS_SUBSCRIBTIONS,
  },
  // q
  // r
  // s
  SCRIPTS,
  SETTINGS: {
    CDN: cdnAPi,
    APKS: apksApi,
    GENERAL: generalSettingsApi,
    DOCUMENTS: documentsApi,
  },
  STORE_LINKS,

  STREAMER: {
    SERVERS: streamerServersApi,
    STREAMS: streamerStreamsApi,
  },

  // t
  TRANSCODERS: {
    GROUPS: TRANSCODERS_GROUPS,
    RESOLUTIONS: ResolutionsApi,
    SERVERS: transcodersServersApi,
    TRANSCODING: transcoderTranscodingApi,
  },
  // u
  USERS: {
    USER: userApi,
    USERS: usersApi,
    GROUPS: usersGroupsApi,
  },
  // v
  VOD: {
    CAST: castApi,
    GENRES: vodGenresApi,
    TRANSCODING: vodTranscodingApi,
    COLLECTIONS: vodCollectionsApi,
    CONTENT_PROVIDERS: vodContentProvidersApi,

    COLLECTION_PAGE: collectionPageApi,

    MEDIAS: mediasApi,

    MEDIA_CONTENT: mediaApi,
  },
  // w
  WEB_HOOKS,
  WIDGETS: GENERAL.WIDGETS,

  WEB_PAGE: {
    FAQ,
    NEWS,
    SLIDER,
    FEATURES: webFeaturesApi,
    PLATFORMS: webPlatformsApi,
    SETTINGS: WEB_SETTINGS,
    AVAILABLE_DEVICES: _webAvailableDevicesApi,
  },
  // x
  // y
  // z
};

export default REQUESTS;
