import { useEffect, useState } from "react";

import REQUESTS from "../../../../../api/requests";

import styles from "./totals.module.scss";
import Total from "./Total";
import icons from "../../../../../config/icons";

export default function Totals() {
  const [totals, setTotals] = useState(null);

  const getTotals = () => {
    REQUESTS.DASHBOARD.VOD.TOTALS()
      .then((response) => {
        if (!response.error) {
          setTotals(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTotals();
  }, []);

  return (
    <div className={styles["totals"]}>
      <Total title="Movies" count={totals?.totalMovies || 0} icon={icons.VOD} />

      <Total title="Tv Shows" count={totals?.totalTvShows || 0}>
        <div>
          <div className={styles["row"]}>
            <p>Seasons</p>
            <span>{totals?.totalSeasons || 0}</span>
          </div>

          <div className={styles["row"]}>
            <p>Episodes</p>
            <span>{totals?.totalEpisodes || 0}</span>
          </div>
        </div>
      </Total>

      <Total title="Genres" count={totals?.totalGenres || 0} icon={icons.SETTINGS} />
      <Total title="Collections" count={totals?.totalCollections || 0} icon={icons.ADS} />
      <Total
        title="Now Watching"
        count={totals?.now_watching_users_count || 0}
        icon={icons.USERS}
      />
    </div>
  );
}
