import { useContext, useEffect, useState } from "react";
import { Divider, Form, Input, DatePicker, Select, Button } from "antd";

import icons from "../../../../../config/icons";
import REQUESTS from "../../../../../api/requests";

import { useCountriesOptions } from "../../../../../hooks/selectOptions";

import VideoModal from "../../../../../_components/VideoModal";
import Container from "../../../../../_components/container/Container";

import styles from "./_meta_data.module.scss";
import { streamTypeOptions } from "../../../../../config/config";
import TranscodingSearch from "../../../../../_components/searches/TranscodingSearch";
import { TabContext } from "../../MediaContentPage";

export default function BasicInfo({ form }) {
  const {
    streamUrl,
    setTrailerUrl,
    mediaType,
    transcodingId,
    trailerUrl,
    trailerStreamType,
    genresList,
  } = useContext(TabContext);

  const countriesOptions = useCountriesOptions();
  const [streamType, setStreamType] = useState("external");
  const [urlValidation, setUrlValidation] = useState(true);

  const [isOpenTrilerVideo, setIsOpenTrilerVideo] = useState(false);
  const [selectedTranscodingIdForTrailer, setSelectedTranscodingIdForTrailer] =
    useState("");

  const [streamData, setStreamData] = useState({
    id: "",
    url: "",
  });
  const [requiredUrl, setRequiredUrl] = useState(false);

  const generateUrlFromTranscodingForTrailer = (id) => {
    REQUESTS.VOD.TRANSCODING.GET({
      query: JSON.stringify({
        filter: {
          id,
        },
      }),
    }).then((response) => {
      if (response?.rows) {
        const transcoding = response.rows[0];
        form.setFieldsValue({
          trailer_transcoding_id: transcoding?.id,
        });
        setStreamData({
          id: transcoding?.id,
          url: transcoding?.stream_url,
        });
      }
    });
  };

  const handleStreamType = (value) => {
    form.setFieldsValue({ trailer_stream_type: value });
    setStreamType(value);
  };

  const handleChange = ({ target: { value } }) => {
    const pattern = /^(https?):\/\/[^\s/$.?#].[^\s]*$/;
    const isValid = pattern.test(value);
    setUrlValidation(isValid);
    if (isValid) {
      setStreamData({ ...streamData, url: value });
    }
    setTrailerUrl(value);
  };

  useEffect(() => {
    if (streamType === "internal") {
      generateUrlFromTranscodingForTrailer(selectedTranscodingIdForTrailer);
    }
  }, [selectedTranscodingIdForTrailer]);

  useEffect(() => {
    if (trailerStreamType === "internal" || streamType === "internal") {
      generateUrlFromTranscodingForTrailer(
        selectedTranscodingIdForTrailer || transcodingId
      );
    }

    if (trailerStreamType === "external" && streamType === "external") {
      setStreamData({ ...streamData, url: trailerUrl });
    } else {
      setStreamData({ ...streamData, url: "" });
    }
  }, [streamType]);

  useEffect(() => {
    if (!streamUrl && mediaType !== "tv_show") {
      setRequiredUrl(() => true);
    } else {
      setRequiredUrl(() => false);
    }
  }, [streamUrl, mediaType]);

  useEffect(() => {
    setStreamType(trailerStreamType);
  }, [trailerStreamType]);

  return (
    <Container>
      <Divider orientation="left" plain>
        <b>Basic info</b>
      </Divider>

      <div className={styles["release-audio"]}>
        <Form.Item label="Release date" name="release_date">
          <DatePicker format={"YYYY-MM-DD"} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item label="Original audio language" name="original_audio_language">
          <Input />
        </Form.Item>
      </div>

      <Form.Item label="Genre" name="genres">
        <Select
          mode="multiple"
          showSearch={true}
          options={genresList}
          onChange={(value) => {
            const genres = {
              name: value,
            };

            form.setFieldsValue({ genres: genres });
          }}
          filterOption={(input, option) =>
            (option?.label?.toLowerCase() ?? "").includes(input?.toLocaleLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item label="Country" name="countries">
        <Select
          mode="multiple"
          showSearch={true}
          options={countriesOptions}
          filterOption={(input, option) =>
            (option?.label?.toLowerCase() ?? "").includes(input?.toLocaleLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item
        label="Stream type"
        name="trailer_stream_type"
        rules={[{ required: true, message: "Please select a stream type" }]}
      >
        <Select
          options={streamTypeOptions}
          onChange={(value) => handleStreamType(value)}
          style={{ width: "100%" }}
        />
      </Form.Item>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: 10,
        }}
      >
        {streamType === "internal" ? (
          <Form.Item
            label="Trailer url"
            name="trailer_url_id"
            onChange={handleChange}
            shouldUpdate
            style={{ width: "100%" }}
            rules={[
              {
                required: true || urlValidation.isValid,
                message:
                  (!urlValidation.isValid && urlValidation.message) ||
                  "Please input a url",
              },
            ]}
          >
            <TranscodingSearch
              width="100%"
              value={selectedTranscodingIdForTrailer}
              onChange={(value) => {
                setSelectedTranscodingIdForTrailer(value);
              }}
            />
          </Form.Item>
        ) : (
          <Form.Item
            label="Trailer url"
            name="trailer_url"
            style={{ width: "100%" }}
            rules={[
              {
                required: requiredUrl,
                validator: (_, value) => {
                  if (!value && requiredUrl) {
                    return Promise.reject(new Error("Please input a URL"));
                  }
                  if (!urlValidation && value) {
                    return Promise.reject(new Error("Please enter valid URL"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input onChange={handleChange} />
          </Form.Item>
        )}

        <div />
        <div>
          <Button
            type="primary"
            icon={icons.PLAY}
            style={{
              marginTop: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => setIsOpenTrilerVideo(true)}
            disabled={(streamType === "external" && !urlValidation) || !streamData?.url}
          />
        </div>
      </div>

      <VideoModal
        title="Triler"
        open={isOpenTrilerVideo}
        url={streamData?.url}
        onClose={() => {
          setIsOpenTrilerVideo(false);
        }}
      />
    </Container>
  );
}
