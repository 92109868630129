const initialState = {
    profile: null,
    dashboardSettings: null,
    permissions: null,
    uploading_list: [],
    show_menu_item: null,
    new_message: null,
    isChangedNotReadMessagesCount: false,
};

const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case "profile":
            return { ...state, profile: action.payload };

        case "dashboardSettings":
            return { ...state, dashboardSettings: action.payload };

        case "permissions":
            return { ...state, permissions: action.payload };

        case "new_message":
            return { ...state, new_message: action.payload };

        case "show_menu_item":
            return { ...state, show_menu_item: action.payload };

        case "isChangedNotReadMessagesCount":
            return { ...state, isChangedNotReadMessagesCount: action.payload };

        case "uploading_list":
            let newArr = [...state.uploading_list];
            const index = newArr.findIndex((x) => x.id === action.payload.id);
            index === -1
                ? newArr.push(action.payload)
                : (newArr[index].progress = action.payload.progress);

            if (action.payload.progress === 100 || action.payload.progress == undefined) {
                newArr.splice(index, 1);
            }

            return { ...state, uploading_list: newArr };

        default:
            return state;
    }
};
export default globalReducer;
