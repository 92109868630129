import { useEffect, useState } from "react";

import REQUESTS from "../../../../../api/requests";

import styles from "./most_watched.module.scss";

export default function Movie() {
    const [movie, setMovie] = useState([]);

    const getData = () => {
        REQUESTS.DASHBOARD.VOD.MOST_WATCHED().then((response) => {
            if (!response.error) {
                setMovie(response.message?.[0]);
            }
        });
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div>
            <div className={styles["most-watched"]}>
                <img src={movie?.movie?.poster} />

                {/* <div className={styles["container"]}>
                    <p className={styles["title"]}>{movie?.movie?.name}</p>
                    <p>Type: {movie?.movie?.type}</p>

                    <p>Year: {movie?.movie?.year}</p>

                    <p>Duration: {movie?.movie?.duration}</p>
                </div> */}
            </div>
        </div>
    );
}
