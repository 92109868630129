import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
    RESOLUTIONS: `${host}admin/resolutions`,
};

export default {
    GET: (query) => request("GET", URL.RESOLUTIONS, query),

    ADD: (query) => request("POST", URL.RESOLUTIONS, query),

    EDIT: (query) => request("PUT", URL.RESOLUTIONS, query),

    DELETE: (query) => request("DELETE", URL.RESOLUTIONS, query),
};
