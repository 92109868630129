import { useEffect, useState } from "react";

import { Divider, Form, Input, Select, notification } from "antd";

import REQUESTS from "../../../../../api/requests";

import Container from "../../../../../_components/container/Container";

import styles from "./_details.module.scss";
import ButtonComponent from "../../../../../_components/button/ButtonComponent";
import icons from "../../../../../config/icons";
// import ButtonComponent from "../../../../../components/ButtonComponent";

export default function Translations({ editableMovieId }) {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [languagesOptions, setLanguagesOption] = useState([]);

  const [movieTranslation, setMovieTranslation] = useState(null);

  const onFinish = (values) => {
    setIsLoading(true);

    const body = {
      model: "movie",
      name: values.name,
      description: values.description,
    };

    if (movieTranslation) {
      body.id = movieTranslation.id;

      REQUESTS.VOD.MEDIA_CONTENT.TRANSLATIONS.EDIT(body).then((res) => {
        if (res.error) {
          notification.error({
            message: "Error",
            description: res.message,
          });

          return;
        }

        notification.success({
          message: "Success",
          description: "Translation has been updated",
        });

        getTranslations();

        setIsLoading(false);
      });
    } else {
      body.id = editableMovieId;
      body.languageId = values.languageId;

      REQUESTS.VOD.MEDIA_CONTENT.TRANSLATIONS.ADD(body).then((res) => {
        if (res.error) {
          notification.error({
            message: "Error",
            description: res.message,
          });

          return;
        }

        notification.success({
          message: "Success",
          description: "Translation has been added",
        });

        getTranslations();

        setIsLoading(false);
      });
    }
  };

  const getTranslations = () => {
    REQUESTS.VOD.MEDIA_CONTENT.TRANSLATIONS.GET(editableMovieId, "movie").then((res) => {
      if (!res.error) {
        const languages = res.message.map((item) => {
          return {
            label: item.name,
            value: item.id,
            ...item,
          };
        });

        setLanguagesOption(languages);

        const languageId = form.getFieldValue("languageId");

        if (!languageId) {
          const eng = languages.find((item) => item.iso_code === "en");
          form.setFieldsValue({
            languageId: eng.id,
          });
        }
      }
    });
  };

  const onFieldsChange = (changedFields, allFields) => {
    if (changedFields[0]?.name[0] === "languageId") {
      const language = languagesOptions.find(
        (item) => item.id === changedFields[0].value
      );

      setMovieTranslation(language.movie_translation);
    }
  };

  useEffect(() => {
    if (movieTranslation) {
      form.setFieldsValue({
        name: movieTranslation.name,
        description: movieTranslation.description,
      });
    } else {
      form.setFieldsValue({
        name: "",
        description: "",
      });
    }
  }, [movieTranslation]);

  useEffect(() => {
    getTranslations();
  }, []);

  return (
    <Container>
      <Divider orientation="left" plain>
        <b>Translations</b>
      </Divider>

      <Form
        disabled={!editableMovieId}
        form={form}
        name="media-translation"
        layout="vertical"
        onFinish={onFinish}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            form.submit();
          }
        }}
        onFieldsChange={onFieldsChange}
        style={{ opacity: editableMovieId ? 1 : 0.4 }}
      >
        <Form.Item label="Language" name="languageId">
          <Select
            showSearch
            options={languagesOptions}
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <Input.TextArea rows={5} style={{ resize: "none" }} />
        </Form.Item>

        {editableMovieId && (
          <Form.Item style={{ textAlign: "center" }}>
            <ButtonComponent
              title={"save"}
              isLoading={isLoading}
              icon={icons.CHECK}
              onClick={() => form.submit()}
            />
          </Form.Item>
        )}
      </Form>

      {!editableMovieId && (
        <div className={styles["warrning"]}>
          You can update this content after you will fill app all the required content of
          the movie or tv show
        </div>
      )}
    </Container>
  );
}
