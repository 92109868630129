import request from "../../../api/request";

import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
  MESSAGE: `${host}launcher/admin/message`,
};

const launcherMessagesApi = {
  GET: (query) => request("GET", URL.MESSAGE, query),

  SEND: (query) => request("POST", URL.MESSAGE, query),
};

export default launcherMessagesApi;
