import request from "../../api/request";

import { DEMO_API_HOST } from "../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
  PLAYLIST: `${host}admin/parse_playlist`,
};

const importApi = {
  PARSE: (query) => request("GET", URL.PLAYLIST, query),

  ADD: (query) => request("POST", URL.PLAYLIST, query),

  STOP: (query) => request("DELETE", URL.PLAYLIST, query),
};

export default importApi;
