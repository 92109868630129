import request from "../../../api/request";

import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
    ICONS: `${host}admin/users/icons`,
};

export const userIconHost = URL.ICONS;

const userIconsApi = {
    GET: (query) => request("GET", URL.ICONS, query),

    ADD: (query) => request("POST", URL.ICONS, query),

    DELETE: (query) => request("DELETE", URL.ICONS, query),
};

export default userIconsApi;
