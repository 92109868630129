import request from "../../request";

const host = `${process.env.REACT_APP_API_HOST || "DEFAULT_REACT_API_HOST"}`;

const URL = {
  COUNTRY: `${host}movie/country`,
};

export default {
  GET: (query) => request("GET", URL.COUNTRY, query),
};
