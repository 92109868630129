import { useEffect, useState } from "react";
import ChartComponent from "../../../../../../_components/chart/ChartComponent";
import { Area } from "@ant-design/plots";
import REQUESTS from "../../../../../../api/requests";
import styles from "../../../../styles/_charts.module.scss";
import { Empty } from "antd";

const RegisteredUsersCart = () => {
  const [isEmpty, setIsEmpty] = useState(false);

  const [selectedData, setSelectedData] = useState([
    {
      id: 0,
      name: "All time",
      query: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
        type: "year",
        value: -1,
      },
      active: false,
    },
    {
      id: 1,
      name: "Year",
      query: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
        type: "month",
        value: -1,
      },
      active: false,
    },
    {
      id: 2,
      name: "Month",
      query: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
        type: "day",
        value: -1,
      },
      active: true,
    },
  ]);

  const [data, setData] = useState({
    data: [],
    xField: "date",
    yField: "value",
    label: {},
    point: {
      size: 5,
      shape: "circle",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  });

  const getStatistics = (query) => {
    const params = JSON.stringify(query);

    REQUESTS.DASHBOARD.REGISTERED_USERS({ query: params })
      .then((response) => {
        if (!response.error) {
          setData({
            ...data,
            data: response?.message?.data,
          });
        }
      })
      .catch((err) => {
        console.log(`err`, err);
      });
  };

  useEffect(() => {
    getStatistics(selectedData[2].query);
  }, []);

  useEffect(() => {
    !data.data.length ? setIsEmpty(true) : setIsEmpty(false);
  }, [data]);

  const handleSelectTab = (value) => {
    const selectActive = selectedData.filter((item) => {
      if (item.name === value.name) {
        return {
          ...item,
          active: true,
        };
      } else {
        return {
          ...item,
          active: false,
        };
      }
    });

    setSelectedData(selectActive);
    getStatistics(value.query);
  };

  return (
    <div className={styles["chart-wrapper"]}>
      <ChartComponent
        title="Registered Users"
        children={isEmpty ? <Empty style={{ height: 400 }} /> : <Area {...data} />}
        radioGroup={selectedData}
        selectedTab={(item) => handleSelectTab(item)}
      />
    </div>
  );
};

export default RegisteredUsersCart;
