import { useEffect, useState } from "react";

import { Drawer, Form, Input, notification, Select } from "antd";

import REQUESTS from "../../../api/requests";

import { useLiveTvOptions } from "../../../hooks/selectOptions";

import ButtonComponent from "../../../_components/ButtonComponent";

export default function LiveTvCategoryDrawer({ onClose, open, editable, getData }) {
  const [isLoading, setIsLoading] = useState(false);

  const liveTvOptions = useLiveTvOptions();

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    const body = {
      name: values.name,
      channels_id: values.channels_id,
    };

    if (editable) {
      body.id = editable.id;

      REQUESTS.LIVE_TV.CATEGORIES.EDIT(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              description: response.message,
            });

            return;
          }

          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      REQUESTS.LIVE_TV.CATEGORIES.ADD(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              description: response.message,
            });

            return;
          }
          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }

    if (editable) {
      const channels_id = editable.channels?.map((item) => item.id);

      form.setFields([
        { name: "name", value: editable.name },
        { name: "channels_id", value: channels_id },
      ]);
    }
  }, [open, editable]);

  return (
    <Drawer
      title={`${editable ? "Edit" : "Add"} category`}
      placement="right"
      onClose={onClose}
      open={open}
    >
      <Form
        form={form}
        name="live-tv-category"
        layout="vertical"
        onFinish={onFinish}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            form.submit();
          }
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Live Tv"
          name="channels_id"
          rules={[
            {
              required: false,
              message: "Please select channels",
            },
          ]}
        >
          <Select
            showArrow
            options={liveTvOptions}
            mode="multiple"
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? "").includes(input?.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title="Save"
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
