import { useEffect, useState } from "react";
import { Layout, Menu } from "antd";

import { bindActionCreators } from "redux";

import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";

import * as actions from "../../redux-store/actions";

import defaultOpenKeys from "./defaultOpenKeys";
import checkPermissions from "./checkPermissions";

import REQUESTS from "../../api/requests";
import requests from "../../server/requests";

import style from "./sidebar.module.scss";

export default function Sidebar({ collapsed }) {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const location = useLocation();

    const { permissions, dashboardSettings } = useSelector((state) => state.globalState);

    const { setPermissions } = bindActionCreators(actions, dispatch);

    const [current, setCurrent] = useState(location.pathname);

    useEffect(() => {
        setCurrent(location.pathname);
    }, [location.pathname]);

    const [routes, setRoutes] = useState([]);

    const onClick = (e) => {
        setCurrent(e.key);
    };

    const getPermissions = () => {
        REQUESTS.PROFILE.PERMISSIONS()
            .then((response) => {
                if (response.error) {
                    return;
                }

                setPermissions(response.message);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getMovieTranscoderHost = () => {
        requests.vod.movie_transcoder_host((data) => {
            localStorage.removeItem("movie_transcoder_host");
            if (data.movie_transcoder_host) {
                localStorage.setItem(
                    "movie_transcoder_host",
                    // `${data.movie_transcoder_host}/transcodings`
                    `${data.movie_transcoder_host}/`
                );
            }

            if (data.movie_transcoder_key) {
                localStorage.setItem("movie_transcoder_key", `${data.movie_transcoder_key}`);
            }
        });
    };

    useEffect(() => {
        getMovieTranscoderHost();
        getPermissions();
    }, []);

    useEffect(() => {
        navigate(current);
    }, [current]);

    useEffect(() => {
        const list = checkPermissions(permissions);

        setRoutes(list);
    }, [permissions]);

    return (
        <Layout.Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            className={style["sidebar"]}
            style={{
                height: "100vh",
                position: "fixed",
                left: 0,
                top: 0,
                bottom: 0,
            }}
        >
            <header style={{ height: collapsed ? 100 : 150 }}>
                {dashboardSettings?.logo && (
                    <img alt="Inorain" width={collapsed ? 50 : 140} src={dashboardSettings?.logo} />
                )}
            </header>

            <div className={style["sidebar-menu"]}>
                <Menu
                    onClick={onClick}
                    defaultOpenKeys={[defaultOpenKeys(location.pathname)]}
                    onDoubleClick={() => window.location.reload()}
                    selectedKeys={[current]}
                    mode="inline"
                    items={routes}
                    className="antd-sidebar-menu"
                />
            </div>
        </Layout.Sider>
    );
}
