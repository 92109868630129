import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
    CHANNELS: `${host}v2/admin/streaming/channels`,
};

const streamerStreamsApi = {
    GET: (query) => request("GET", URL.CHANNELS, query),

    ADD: (query) => request("POST", URL.CHANNELS, query),

    EDIT: (query) => request("PUT", URL.CHANNELS, query),

    DELETE: (query) => request("DELETE", URL.CHANNELS, query),

    PLAY: (query) => request("PUT", `${URL.CHANNELS}/play`, query),

    PAUSE: (query) => request("PUT", `${URL.CHANNELS}/pause`, query),
};

export default streamerStreamsApi;
