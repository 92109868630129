import { useState, useEffect } from "react";
import ChartComponent from "../../../../../_components/chart/ChartComponent";
import { Pie } from "@ant-design/plots";
import REQUESTS from "../../../../../api/requests";
import styles from "../../../styles/_charts.module.scss";
import { Empty } from "antd";

const TopWatchedChart = () => {
  const [isEmpty, setIsEmpty] = useState(false);
  const [watchedData, setWatchedData] = useState({
    appendPadding: 10,
    data: [],
    angleField: "counter",
    colorField: "movie",
    // radius: 0.9,
    label: {
      type: "inner",
      // content: "{name} {percentage}",
      content: " ",
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
  });

  const getTopWatched = () => {
    REQUESTS.DASHBOARD.VOD.TOP_WATCHED()
      .then((response) => {
        if (!response.error) {
          const dataArray = response?.message?.map((item) => {
            return {
              movie: item.movie?.name,
              counter: item?.counter,
            };
          });
          setWatchedData({
            ...watchedData,
            data: dataArray,
          });
        }
      })
      .catch((err) => {
        console.log(`err`, err);
      });
  };

  useEffect(() => {
    getTopWatched();
  }, []);

  useEffect(() => {
    !watchedData.data?.length ? setIsEmpty(true) : setIsEmpty(false);
  }, [watchedData]);

  return (
    <div className={styles["chart-wrapper"]}>
      <ChartComponent
        title="Top watched"
        children={
          isEmpty ? (
            <Empty style={{ height: 400 }} />
          ) : (
            <Pie {...watchedData} style={{ height: 450 }} />
          )
        }
      />
    </div>
  );
};
export default TopWatchedChart;
