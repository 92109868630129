import { useEffect, useState } from "react";
import { Divider, Form, Input, notification } from "antd";

import REQUESTS from "../../../../api/requests";

import ButtonComponent from "../../../../_components/ButtonComponent";

export default function OthersTab() {
  const [isLoading, setIsLoading] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);
    setIsDisabled(true);

    const body = {
      image: values.image,
      archive: values.archive,
    };

    REQUESTS.SETTINGS.CDN.UPDATE_OTHERS(body)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message,
          });

          return;
        }

        notification.success({
          message: "Success",
          description: "Urls updated successfully",
        });

        getData();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getData = () => {
    setIsDisabled(true);
    REQUESTS.SETTINGS.CDN.GET_OTHERS().then((response) => {
      setIsDisabled(false);
      if (!response.error) {
        const { image, archive } = response.message;

        form.setFields([
          { name: "image", value: image?.trim() },
          { name: "archive", value: archive?.trim() },
        ]);
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <Divider orientation="left">Others</Divider>
      <Form
        form={form}
        name="cdn"
        layout="vertical"
        onFinish={onFinish}
        style={{ width: 400 }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            form.submit();
          }
        }}
        disabled={isDisabled}
      >
        <Form.Item label="Image" name="image">
          <Input />
        </Form.Item>

        <Form.Item label="Archive" name="archive">
          <Input />
        </Form.Item>

        <Form.Item>
          <ButtonComponent
            title="Save"
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </div>
  );
}
