import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
  COLLECTIONS: `${host}v2/admin/collections`,

  MOVIES: `${host}v2/admin/collections/movies`,
};

const vodCollectionsApi = {
  GET: (query) => request("GET", URL.COLLECTIONS, query),

  ADD: (query) => request("POST", URL.COLLECTIONS, query),

  EDIT: (query) => request("PUT", URL.COLLECTIONS, query),

  DELETE: (query) => request("DELETE", URL.COLLECTIONS, query),

  SORT: (query) => request("PUT", `${URL.COLLECTIONS}/sort`, query),

  PROTECT: (query) => request("PUT", `${URL.COLLECTIONS}/protect`, query),

  POSITIONS: (query) => request("PUT", `${URL.COLLECTIONS}/positions`, query),

  MOVIES: {
    GET: (query) => request("GET", URL.MOVIES, query),

    ADD: (query) => request("POST", URL.MOVIES, query),

    SORT: (query) => request("PUT", `${URL.MOVIES}/sort`, query),

    DELETE: (query) => request("DELETE", URL.MOVIES, query),
  },
};

export default vodCollectionsApi;
