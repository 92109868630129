import { useEffect, useState } from "react";

import icons from "../../../config/icons";

import TableButtons from "../../../_components/table/TableButtons";

import TableComponent from "../../../_components/table/TableComponent";

import ButtonComponent from "../../../_components/button/ButtonComponent";

export default function ResolutionsTable({
  getAgain,
  openDrawer,
  getDataSource,
  handleMenuClick,
}) {
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      render: (record, text, index) => {
        return index + 1;
      },
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },

    {
      title: "Width",
      dataIndex: "width",
      key: "width",
      align: "center",
    },

    {
      title: "Height",
      dataIndex: "height",
      key: "height",
      align: "center",
    },

    {
      title: "Fps",
      dataIndex: "fps",
      key: "fps",
      align: "center",
    },

    {
      title: "Audio bitrate",
      dataIndex: "audio_bitrate",
      key: "audio_bitrate",
      align: "center",
    },

    {
      title: "Buffer size",
      dataIndex: "buffer_size",
      key: "buffer_size",
      align: "center",
    },

    {
      title: "Max bitrate",
      dataIndex: "max_bitrate",
      key: "max_bitrate",
      align: "center",
    },

    {
      title: "Min bitrate",
      dataIndex: "min_bitrate",
      key: "min_bitrate",
      align: "center",
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: "Edit",
              icon: icons.EDIT,
            },

            {
              key: "delete",
              name: "Delete",
              icon: icons.DELETE,
              question: `Do you want to delete this resolution?`,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);

    function onSuccess(data) {
      setLoading(false);
      setDataSource(data.rows);
    }

    function onError() {
      setLoading(false);
    }

    getDataSource({ limit: 100 }, onSuccess, onError);
  }, [getAgain]);

  return (
    <TableComponent
      header={
        <>
          <ButtonComponent icon={icons.ADD} title="Add resolution" onClick={openDrawer} />
        </>
      }
      isLoading={loading}
      dataSource={dataSource}
      columns={columns}
      localeClick={openDrawer}
    />
  );
}
