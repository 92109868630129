import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

import REQUESTS from "../../../../../api/requests";
import ChartComponent from "../../../../../_components/chart/ChartComponent";

const CountriesChart = () => {
  const [mapData, setMapData] = useState([["Country", "Subscribers"]]);
  const [error, setError] = useState(null);

  const getCountries = () => {
    REQUESTS.DASHBOARD.COUNTRIES()
      .then((response) => {
        if (!response.error) {
          let country_array = [["Country", "Subscribers"]];
          for (let i = 0; i < response?.message.length; i++) {
            country_array.push([
              response?.message[i].country,
              response?.message[i].count,
            ]);
          }
          setMapData(country_array);
        } else {
          setError(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        marginTop: 20,
      }}
    >
      {error ? (
        <p>{error}</p>
      ) : (
        <div>
          <ChartComponent
            title={"Countries"}
            children={
              <Chart
                chartType="GeoChart"
                data={mapData}
                width="100%"
                height="400px"
                legendToggle
                options={{
                  backgroundColor: "transparent",
                  title: "Countries",
                  colorAxis: { colors: ["black", "#696565", "#00853f"] },
                }}
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export default CountriesChart;
