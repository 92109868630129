import { useState } from "react";
import { Form, Input, notification } from "antd";

import REQUESTS from "../../../../api/requests";

import icons from "../../../../config/icons";

import ButtonComponent from "../../../../_components/button/ButtonComponent";

import styles from "../messages.module.scss";

export default function SendMessage({ id, setGetAgain }) {
    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setIsLoading(true);

        const body = {
            title: values.title,
            message: values.message,
            device_id: id,
        };

        REQUESTS.LAUNCHER.MESSAGES.SEND(body)
            .then((response) => {
                setIsLoading(false);

                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });

                    return;
                }

                notification.success({
                    message: "Success",
                    description: response.message,
                });

                form.resetFields();
                setGetAgain();
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    return (
        <div className={styles["send-message"]}>
            <h4>Send Message</h4>
            <Form
                form={form}
                name="launcher-messages"
                layout="vertical"
                onFinish={onFinish}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        form.submit();
                    }
                }}
                disabled={!id}
            >
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Please input title",
                        },
                    ]}
                >
                    <Input placeholder="Please input title" />
                </Form.Item>

                <Form.Item
                    label="Message"
                    name="message"
                    rules={[
                        {
                            required: true,
                            message: "Please input message",
                        },
                    ]}
                >
                    <Input.TextArea rows={6} placeholder="Please input message" />
                </Form.Item>

                <ButtonComponent
                    title="Send"
                    icon={icons.SEND}
                    isLoading={isLoading}
                    style={{ width: "100%" }}
                    onClick={() => form.submit()}
                />
            </Form>
        </div>
    );
}
