import { useState } from "react";
import { Button, notification, Upload } from "antd";

import REQUESTS from "../../../api/requests";

import { getBase64 } from "../../../config/config";

import style from "./style.module.scss";

export default function AdminPanelFaviconTab({ data, getDashboardSettings }) {
    const [favicon, setFavicon] = useState(data?.value);

    const [file, setFile] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const save = () => {
        setIsLoading(true);

        const formData = new FormData();

        if (file) {
            formData.append("image", file);
        }

        REQUESTS.SETTINGS.GENERAL.FAVICON(formData)
            .then((response) => {
                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });

                    setIsLoading(false);
                } else {
                    notification.success({
                        message: "Success",
                        description: "Favicon is updated",
                    });

                    getDashboardSettings();
                }

                setIsLoading(false);
            })

            .catch((err) => {
                notification.error({
                    message: "Error",
                    description: "Something is wrong",
                });

                setIsLoading(false);
            });
    };

    const onChange = (info) => {
        setFile(info.file.originFileObj);

        if (info.file.status === "uploading") {
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj, (url) => {
                setFavicon(url);
            });
        }
    };

    return (
        <div className={style["tab"]} style={{ width: 300 }}>
            <p className={style["title"]}>{data?.title}</p>

            <p className={style["description"]}>{data?.description}</p>

            <Upload
                accept="image/*"
                customRequest={({ file, onSuccess }) => {
                    setTimeout(() => {
                        onSuccess("ok");
                    }, 0);
                }}
                listType="picture"
                showUploadList={false}
                maxCount={1}
                onChange={onChange}
            >
                {favicon ? (
                    <img
                        src={favicon}
                        alt="avatar"
                        style={{
                            width: "100%",
                        }}
                    />
                ) : (
                    <div>
                        <div
                            style={{
                                marginTop: 8,
                            }}
                        >
                            Upload
                        </div>
                    </div>
                )}
            </Upload>

            <Button
                type="primary"
                onClick={save}
                loading={isLoading}
                style={{ width: "100%", marginTop: 20 }}
            >
                Save
            </Button>
        </div>
    );
}
