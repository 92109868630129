import { Button, Form, Input, Tag } from "antd";

import { useEffect, useState } from "react";

import { useNavigate } from "react-router";

import PATHS from "../../config/paths";

import REQUESTS from "../../api/requests";

import { saveTokenToLocalStorage } from "../../config/config";

import style from "./_content-provider.module.scss";

export default function ContentProviderLogin() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    REQUESTS.CONTENT_PROVIDER.LOGIN(values)
      .then((response) => {
        setIsLoading(false);

        console.log(response);

        if (response.error) {
          setErrorMessage(response.message);

          return;
        }
        // props.history.push(`/content-provider?token=${data}`);

        // saveTokenToLocalStorage(response.message);
        // setPushTokenToServer()

        navigate(PATHS.CONTENT_PROVIDER_PROFILE, {
          state: {
            token: response.message,
          },
        });
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const onClickLogin = () => {
    setErrorMessage(null);

    form.submit();
  };

  const goToLogin = () => {
    navigate("/");
  };

  return (
    <div className={style["page"]}>
      <div className={style["container"]}>
        <h2>Login to content provider dashboard to review your movies statistics</h2>

        <h5>Please sign in bellow.</h5>
        <Form form={form} name="geolocation" layout="vertical" onFinish={onFinish}>
          <Form.Item label="Email">
            <Form.Item
              noStyle
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input email",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form.Item>

          <Form.Item label="Password">
            <Form.Item
              noStyle
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input password",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Form.Item>

          <Form.Item style={{ textAlign: "center" }} noStyle>
            <Button
              type="primary"
              onClick={onClickLogin}
              loading={isLoading}
              style={{ width: "100%" }}
            >
              Login
            </Button>
          </Form.Item>
        </Form>

        <div className={style["error"]}>
          {errorMessage && <Tag color="red">{errorMessage}</Tag>}
        </div>
      </div>

      <div className={style["content-provider-block"]}>
        <div className={style["wrapper"]} onClick={goToLogin}>
          Login as administrator
          <i className="fa fa-arrow-right" style={{ marginLeft: 10 }} />
        </div>
      </div>
    </div>
  );
}
