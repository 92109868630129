import { useState, useEffect } from "react";
import ChartComponent from "../../../../../_components/chart/ChartComponent";
import { Pie } from "@ant-design/plots";
import REQUESTS from "../../../../../api/requests";
import styles from "../../../styles/_charts.module.scss";
import { Empty } from "antd";

const dataMark = [
  {
    id: 0,
    name: "Like",
  },
  {
    id: 1,
    name: "Dislike",
  },
];

const LikeDisLikeChart = () => {
  const [isEmpty, setIsEmpty] = useState(false);
  const [selectedMark, setSelectedMark] = useState("Like");

  const [selectedData, setSelectedData] = useState([
    {
      id: 0,
      name: "All time",
      query: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
        type: "year",
        value: -1,
        content_id: -1,
        content_type: "movie",
      },
      active: true,
    },
    {
      id: 1,
      name: "Year",
      query: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
        type: "month",
        value: -1,
        content_id: -1,
        content_type: "movie",
      },
      active: false,
    },
    {
      id: 2,
      name: "Month",
      query: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
        type: "day",
        value: -1,
        content_id: -1,
        content_type: "movie",
      },
      active: false,
    },
  ]);

  const [dataList, setDataList] = useState({
    appendPadding: 10,
    data: [],
    angleField: "value",
    colorField: "name",
    label: {
      type: "inner",
      content: " ",
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
  });

  const getLikeDisLike = (query) => {
    const params = JSON.stringify(query);

    REQUESTS.DASHBOARD.VOD.LIKE_DISLIKE({ query: params })
      .then((response) => {
        if (!response.error) {
          if (selectedMark == "Like") {
            setDataList({
              ...dataList,
              data: response?.message?.like_count,
            });
          } else {
            setDataList({
              ...dataList,
              data: response?.message?.dislike_count,
            });
          }
        }
      })
      .catch((err) => {
        console.log(`err`, err);
      });
  };

  useEffect(() => {
    getLikeDisLike(selectedData[2].query);
  }, [selectedMark]);

  useEffect(() => {
    !dataList.data.length ? setIsEmpty(true) : setIsEmpty(false);
  }, [dataList]);

  const handleSelectTab = (value) => {
    const selectActive = selectedData?.filter((item) => {
      if (item.name === value.name) {
        return {
          ...item,
          active: true,
        };
      } else {
        return {
          ...item,
          active: false,
        };
      }
    });
    setSelectedData(selectActive);
    getLikeDisLike(value.query);
  };

  const handleDataMark = (value) => {
    if (value.name == "Like") {
      setSelectedMark("Like");
    } else {
      setSelectedMark("Dislike");
    }
  };

  return (
    <div className={styles["chart-wrapper"]}>
      <ChartComponent
        title="Like/Dislike"
        children={isEmpty ? <Empty style={{ height: 400 }} /> : <Pie {...dataList} />}
        radioGroup={selectedData}
        selectedTab={(item) => handleSelectTab(item)}
        dataMark={(item) => handleDataMark(item)}
        markGroup={dataMark}
      />
    </div>
  );
};

export default LikeDisLikeChart;
