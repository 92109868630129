import { Divider } from "antd";
import Movie from "./Movie";

import MoviesList from "./MoviesList";
import NowWatched from "./NowWatched";

import styles from "./most_watched.module.scss";

export default function WatchedBlock() {
  return (
    <div>
      <div className={styles["watched-block"]}>
        <div className={styles["movies-block"]}>
          <div className={styles["title"]}>Most Watched Movies</div>

          <div className={styles["container"]}>
            <MoviesList />
            <Movie />
          </div>
        </div>

        <div className={styles["now-watching-block"]}>
          <div className={styles["title"]}>Now Watching</div>

          <NowWatched />
        </div>
      </div>
    </div>
  );
}
