import request from "../../request";

const host = `${process.env.REACT_APP_API_HOST || "DEFAULT_REACT_API_HOST"}admin/`;

const URL = {
  NEWS: `${host}news`,
};

export default {
  GET: (query) => request("GET", URL.NEWS, query),

  ADD: (query) => request("POST", URL.NEWS, query),

  EDIT: (query) => request("PUT", URL.NEWS, query),

  DELETE: (query) => request("DELETE", URL.NEWS, query),
};
