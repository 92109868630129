import request from "../../request";

const host = `${process.env.REACT_APP_API_HOST || "DEFAULT_REACT_API_HOST"}`;

const URL = {
  PRICING: `${host}movie/pricing`,
};

export default {
  GET: (query) => request("GET", URL.PRICING, query),

  ADD: (query) => request("POST", URL.PRICING, query),

  EDIT: (query) => request("PUT", URL.PRICING, query),

  DELETE: (query) => request("DELETE", URL.PRICING, query),
};
