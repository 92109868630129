import React from "react";
import { Layout, theme } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import style from "./header.module.scss";
import Profile from "./components/profile/Profile";

export default function Header({ collapsed, setCollapsed }) {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <>
            <Layout.Header
                className={style["header"]}
                style={{
                    background: colorBgContainer,
                    width: `calc(100% - ${collapsed ? 80 : 200}px)`,
                }}
            >
                <div className={style["container"]}>
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: "trigger",
                        onClick: () => setCollapsed(!collapsed),
                    })}

                    <Profile />
                </div>
                <hr />
            </Layout.Header>
        </>
    );
}
