import { Switch, Popconfirm } from "antd";
import { useEffect, useState } from "react";

import {
    getQueryDate,
    parseDate,
    getColumnDateProps,
    getColumnSearchProps,
} from "../../../config/config";

import TableComponent from "../../../_components/table/TableComponent";

export default function LauncherDevicesTable({ getAgain, getDataSource, onChange }) {
    const [dataSource, setDataSource] = useState([]);

    const [limit, setLimit] = useState(10);

    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [sort, setSort] = useState(["id", "DESC"]);

    const [date, setDate] = useState(null);

    const [total, setTotal] = useState(0);

    const [search, setSearch] = useState({
        device_model: null,
    });

    const columns = [
        {
            title: "#",
            width: 60,
            dataIndex: `id`,
            align: "center",
            key: "index",
            render: (text, record, index) => {
                return limit * (currentPage - 1) + index + 1;
            },
        },

        {
            title: "Model",
            dataIndex: "device_model",
            key: "device_model",
            align: "center",
            ...getColumnSearchProps(),
        },

        {
            title: "Type",
            dataIndex: "device_type",
            key: "device_type",
            align: "center",
        },

        {
            title: `Date`,
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            render: (text, record, index) => {
                return parseDate(text);
            },
            ...getColumnDateProps(setDate),
        },

        {
            title: "Blocked",
            dataIndex: "is_blocked",
            key: "is_blocked",
            align: "center",

            render: (is_blocked, item, index) => (
                <Popconfirm
                    placement="bottomRight"
                    title={`Do you want to ${is_blocked ? "activate" : "block"} this device`}
                    description={item.device_model}
                    onConfirm={() => onChange(item)}
                    okText={is_blocked ? "Activate" : "Block"}
                    cancelText="Cancel"
                >
                    <Switch size="small" checked={is_blocked} />
                </Popconfirm>
            ),
        },
    ];

    const handleTableChange = (data) => {
        setCurrentPage(data.page);
        setLimit(data.limit);
        setSort(data.sort);

        setSearch(data.search);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(true);

            const query = {
                sort,
                limit,
                page: currentPage,
            };

            if (search.device_model) {
                query.search = {
                    device_model: search.device_model[0],
                };
            }

            const queryDate = getQueryDate(date);

            if (queryDate) query.between = queryDate;

            function onSuccess(data) {
                if (data?.rows.length === 0 && currentPage > 1) {
                    setCurrentPage((prev) => prev - 1);
                    return;
                }

                setLoading(false);
                setDataSource(data.rows);

                setTotal(data?.total);
                setLimit(data?.limit);
                setCurrentPage(data?.page);
            }

            function onError(data) {
                setLoading(false);
            }

            getDataSource(query, onSuccess, onError);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [limit, currentPage, sort, search, date, getAgain]);

    return (
        <TableComponent
            rowClassName={(row) => {
                if (row.is_blocked) return "red-row";
            }}
            isLoading={loading}
            dataSource={dataSource}
            onChange={handleTableChange}
            columns={columns}
            pagination={{
                page: currentPage,
                limit: limit,
                total: total,
            }}
        />
    );
}
