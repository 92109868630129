import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
    APK: `${host}admin/apk`,
};

const apksApi = {
    GET: (query) => request("GET", `${URL.APK}`, query),

    UPDATE: (query) => request("PUT", `${URL.APK}`, query, false),
};

export default apksApi;
