import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
    QUESTION: `${host}admin/question`,
};

const quizApi = {
    GET: (query) => request("GET", URL.QUESTION, query),

    ADD: (query) => request("POST", URL.QUESTION, query),

    EDIT: (query) => request("PUT", URL.QUESTION, query),

    DELETE: (query) => request("DELETE", URL.QUESTION, query),
};

export default quizApi;
