import request from "../../request";

const host = `${process.env.REACT_APP_API_HOST || "DEFAULT_REACT_API_HOST"}`;

const URL = {
  HOOKS: `${host}admin/hooks`,
};

export default {
  GET: (query) => request("GET", `${URL.HOOKS}`, query),

  ADD: (query) => request("POST", `${URL.HOOKS}`, query),

  EDIT: (query) => request("PUT", `${URL.HOOKS}`, query),

  DELETE: (query) => request("DELETE", `${URL.HOOKS}`, query),
};
