import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Space, Tag } from "antd";

import { useUsersOptions } from "../../../hooks/selectOptions";

import icons from "../../../config/icons";
import { getColumnDateProps, getQueryDate, parseDate } from "../../../config/config";

import TableComponent from "../../../_components/table/TableComponent";
import {
  getAtLocal,
  getAtSession,
  setInLocal,
  setInSession,
} from "../../../helpers/storages";
import { asc_desc } from "../../../helpers/ascDesc";

export default function PaymentHistoryTable({ getDataSource }) {
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(getAtLocal("pageLimit_paymentHistory") || 10);

  const [currentPage, setCurrentPage] = useState(
    getAtSession("paymentHistory")?.page || 1
  );

  const [sort, setSort] = useState(
    getAtSession("paymentHistory")?.sort || ["id", "DESC"]
  );

  const [date, setDate] = useState(null);

  const [total, setTotal] = useState(0);

  const [amount, setAmount] = useState(null);

  const [search, setSearch] = useState(
    getAtSession("paymentHistory")?.search || {
      user: null,
    }
  );

  const usersOptions = useUsersOptions("text");

  const columns = [
    {
      width: 60,
      title: "#",
      key: "index",
      dataIndex: `id`,
      fixed: "left",
      align: "center",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: "User",
      defaultFilteredValue: getAtSession("paymentHistory")?.search?.user,
      filteredValue: getAtSession("paymentHistory")?.search?.user,
      dataIndex: "user",
      key: "user",
      align: "center",
      filters: usersOptions,
      filterSearch: true,
      filterMultiple: false,
      onFilter: (value, record) => true,

      render: (text, record, index) => {
        return record.user ? (
          <Link
            to={`/dashboard/user/${record?.user?.id}`}
            style={{ color: "#007bff" }}
            target="_blank"
          >
            {record.user?.name}
          </Link>
        ) : (
          "N/A"
        );
      },
    },

    {
      title: "Amount",
      defaultSortOrder:
        getAtSession("paymentHistory")?.sort?.[0] === "amount" &&
        asc_desc(getAtSession("paymentHistory")?.sort?.[1]),
      key: "amount",
      dataIndex: `amount`,
      align: "center",
      sorter: true,
      width: 120,
    },

    {
      title: "Payment type",
      key: "payment_type",
      dataIndex: `payment_type`,
      align: "center",
    },

    {
      title: "Date",
      defaultFilteredValue: getAtSession("paymentHistory")?.search?.createdAt,
      filteredValue: getAtSession("paymentHistory")?.search?.createdAt,
      key: "createdAt",
      dataIndex: `createdAt`,
      align: "center",
      render: (text, record, index) => {
        return parseDate(text);
      },

      ...getColumnDateProps(setDate),
    },

    {
      title: "Status",
      key: "status",
      dataIndex: `status`,
      align: "center",
      render: (text, record, index) => {
        return record?.status ? (
          <div style={{ color: "red" }}>{icons.ARROW_DOWN}</div>
        ) : (
          <div style={{ color: "green" }}>{icons.ARROW_UP}</div>
        );
      },
    },
  ];

  const handleTableChange = (data) => {
    const paymentHistory = {
      page: data.page,
      search: data.search,
    };
    if (data.sort) {
      paymentHistory.sort = [`${data.sort[0]}`, `${data.sort[1]}`];
    }
    setInLocal("pageLimit_paymentHistory", data.limit);
    setInSession("paymentHistory", paymentHistory);

    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);
  };

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setLoading(true);

  //     const query = {
  //       sort,
  //       limit,
  //       page: currentPage,
  //     };

  //     if (search.user) {
  //       query.filter = {
  //         user_id: search.user[0],
  //       };
  //     }

  //     const queryDate = getQueryDate(date);

  //     if (queryDate) query.between = queryDate;

  //     function onSuccess(data) {
  //       if (data.rows?.length === 0 && currentPage > 1) {
  //         setCurrentPage((prev) => prev - 1);
  //         return;
  //       }

  //       setLoading(false);
  //       setDataSource(data.rows);
  //       setTotal(data?.total);
  //       setLimit(data?.limit);
  //       setCurrentPage(data?.page);

  //       setAmount(data?.amount);
  //     }

  //     function onError() {
  //       setLoading(false);
  //     }

  //     getDataSource({ query: JSON.stringify(query) }, onSuccess, onError);
  //   }, 500);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [limit, currentPage, sort, search, date]);

  useEffect(() => {
    const fetchLiveTvData = () => {
      setLoading(true);

      const query = {
        sort,
        limit,
        page: currentPage,
      };

      if (search.name) {
        query.search = {
          name: search.name,
        };
      }

      if (search.categories) {
        query.filter = {
          categoryId: search.categories,
        };
      }

      function onSuccess(data) {
        setLoading(false);
        setDataSource(data?.rows);
        setTotal(data?.count);

        const maxPage = Math.ceil(data?.count / limit);
        const storedPage = getAtSession("paymentHistory")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("paymentHistory", {
            ...getAtSession("paymentHistory"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("paymentHistory", {
            ...getAtSession("paymentHistory"),
            page: currentPage,
          });
        }
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource({ query: JSON.stringify(query) }, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      if (
        getAtSession("paymentHistory") &&
        getAtSession("paymentHistory")?.page > currentPage
      ) {
        setInSession("paymentHistory", {
          ...getAtSession("paymentHistory"),
          page: 1,
        });
        setCurrentPage(getAtSession("paymentHistory")?.page);
      }

      fetchLiveTvData();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, search, date]);

  return (
    <TableComponent
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
      footer={() => (
        <Space>
          <Tag style={{ fontSize: 14 }}>
            Total: {amount?.total ? amount?.total.toFixed(2) : 0}
          </Tag>
          <Tag style={{ fontSize: 14 }}>
            Spent: {amount?.spent ? amount?.spent.toFixed(2) : 0}
          </Tag>
          <Tag style={{ fontSize: 14 }}>
            Unspent:
            {amount?.unspent ? amount?.unspent.toFixed(2) : 0}
          </Tag>
        </Space>
      )}
    />
  );
}
