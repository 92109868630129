export default function redirectToStepOfTheErrorField(list, setCurrentStep) {
  for (let i = 0; i < list.length; i++) {
    console.log(list[i].name[0]);

    switch (list[i].name[0]) {
      case "name":
        setCurrentStep("details");
        return;

      case "poster":
        setCurrentStep("gallery");
        return;

      case "stream_url":
      case "stream_type":
        setCurrentStep("stream");
        return;

      case "trailer_url_id":
        setCurrentStep("meta-data");
        return;
      case "transcoding_id":
        setCurrentStep("stream");
        return;

      case "tariffIds":
      case "pricing_id":
        setCurrentStep("restriction_monetization");
        return;

      case "trailer_url":
        setCurrentStep("meta-data");
        return;

      default:
        break;
    }
  }
}
