import request from "../../request";

const host = `${process.env.REACT_APP_API_HOST || "DEFAULT_REACT_API_HOST"}`;

const URL = {
  ARCHIVE: `${host}archive/`,
};

export default {
  SERVERS: {
    GET: (query) => request("POST", `${URL.ARCHIVE}/get`, query),
  },
};
