import React, { useState, useEffect } from "react";
import {
  Drawer,
  Form,
  Input,
  Button,
  message,
  Divider,
  Select,
  notification,
} from "antd";
import TextareaComponent from "../../../../../../components/TextareaComponent";
import { useLanguagesOptions } from "../../../../../../hooks/selectOptions";
import ButtonComponent from "../../../../../../_components/button/ButtonComponent";
import icons from "../../../../../../config/icons";

const AddSeasonDrawer = ({
  show,
  close,
  addOrUpdateSeason,
  isEditMode,
  initialValues,
  selectedSeasonId,
  getTranslation,
  translations,
  addSeasonTranslation,
}) => {
  const languagesOptions = useLanguagesOptions();

  const [form] = Form.useForm();
  const [translationForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [translationAddLoading, setTranslationAddLoading] = useState(false);
  const [translationLanguage, setTranslationLanguage] = useState();

  const handleSubmit = async (values) => {
    const body = {
      ...values,
    };
    try {
      setLoading(true);
      const type = isEditMode ? "put" : "post";
      await addOrUpdateSeason(body, type);
      message.success(
        isEditMode ? "Season updated successfully" : "Season added successfully"
      );
      close();
    } catch (error) {
      console.error("Error occurred:", error);
      message.error(isEditMode ? "Failed to update season" : "Failed to add season");
    } finally {
      setLoading(false);
    }
  };

  const submitTranslation = (values) => {
    console.log(values);
    const body = {
      id: selectedSeasonId,
      languageId: translationLanguage,
      model: "season",
      name: values.translation_name,
      description: values.translation_description,
    };
    setTranslationAddLoading(true);

    const success = (data) => {
      setTranslationAddLoading(false);
      notification.success({
        success: "success",
        message: "Translation added successfully",
      });
    };

    const error = (error) => {
      console.log(error);
      notification.error({
        error: "error",
        message: "Something wrong",
      });
      setTranslationAddLoading(false);
    };

    addSeasonTranslation(body, success, error);
  };

  useEffect(() => {
    if (isEditMode) {
      const value = initialValues?.find((value) => value.id === selectedSeasonId);
      form.setFieldsValue({
        name: value?.name,
        number: value?.number,
        description: value?.description,
      });
    }
    getTranslation(selectedSeasonId, "season");
  }, [isEditMode, initialValues, form]);

  useEffect(() => {
    const languageId = translationForm.getFieldValue("languageId");

    if (!languageId) {
      const eng = languagesOptions.find((item) => item.iso_code === "en");
      translationForm.setFieldsValue({
        languageId: eng?.id,
      });
      setTranslationLanguage(eng?.id);
    }

    const newSeasonTranslation = translations?.find(
      (item) => item.id == translationLanguage
    );

    if (newSeasonTranslation?.season_translation) {
      translationForm.setFields([
        {
          name: "translation_name",
          value: newSeasonTranslation.season_translation.name || "",
        },
        {
          name: "translation_description",
          value: newSeasonTranslation.season_translation.description || "",
        },
      ]);
    } else {
      translationForm.setFields([
        {
          name: "translation_name",
          value: "",
        },
        {
          name: "translation_description",
          value: "",
        },
      ]);
    }
  }, [translationLanguage, translationForm, translations]);

  return (
    <Drawer
      title={isEditMode ? "Edit Season" : "Add Season"}
      open={show}
      onClose={() => {
        close();
        form.resetFields();
        translationForm.resetFields();
      }}
      width={500}
      footer={
        <div
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <Button onClick={close}>Cancel</Button>
          <Button type="primary" onClick={() => form.submit()} loading={loading}>
            {isEditMode ? "Save" : "Add"}
          </Button>
        </div>
      }
    >
      <Form id="seasonForm" form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please enter the name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="number"
          label="Season Number"
          rules={[{ required: true, message: "Please enter the season number" }]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item name="description" label="Description">
          <TextareaComponent />
        </Form.Item>
        {isEditMode && (
          <Form form={translationForm} onFinish={submitTranslation} layout="vertical">
            <Divider orientation="left">Translations</Divider>

            <Form.Item name="languageId" label="Language">
              <Select
                showSearch
                options={languagesOptions}
                onChange={(e) => {
                  setTranslationLanguage(e);
                }}
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input?.toLocaleLowerCase()
                  )
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item name="translation_name" label="Name">
              <Input />
            </Form.Item>
            <Form.Item name="translation_description" label="Description">
              <TextareaComponent />
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ButtonComponent
                title={"Save translation"}
                icon={icons.CHECK}
                isLoading={translationAddLoading}
                onClick={() => {
                  translationForm.submit();
                }}
              />
            </div>
          </Form>
        )}
      </Form>
    </Drawer>
  );
};

export default AddSeasonDrawer;
