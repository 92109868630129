import request from "../../api/request";
import { DEMO_API_HOST } from "../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
    EPG: `${host}admin/epg`,

    TYPES: `${host}admin/epg/types`,

    SEARCH: `${host}admin/epg/search`,
};

const epgApi = {
    GET: (query) => request("GET", URL.EPG, query),

    ADD: (query) => request("POST", URL.EPG, query),

    EDIT: (query) => request("PUT", URL.EPG, query),

    DELETE: (query) => request("DELETE", URL.EPG, query),

    PUBLISH: (query) => request("POST", `${URL.EPG}/publish`, query),

    UNPUBLISH: (query) => request("POST", `${URL.EPG}/unpublish`, query),

    UPDATE: (query) => request("POST", `${URL.EPG}/update/list`, query),

    FORCE_UPDATE: (query) => request("POST", `${URL.EPG}/update/force`, query),

    TYPES: (query) => request("POST", URL.TYPES, query),

    SEARCH: (query) => request("POST", URL.SEARCH, query),
};

export default epgApi;
