import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
  CATEGORY: `${host}admin/live_tv_category`,
  ARCHIVE: `${host}admin/live_tv_category/archive`,
  POSITION: `${host}admin/live_tv_category/position`,
  PROTECTED: `${host}admin/live_tv_category/protected`,
};

const liveTvCategoriesApi = {
  GET: (query) => request("GET", URL.CATEGORY, query),

  ADD: (query) => request("POST", URL.CATEGORY, query),

  EDIT: (query) => request("PUT", URL.CATEGORY, query),

  DELETE: (query) => request("DELETE", URL.CATEGORY, query),

  DELETE_BULK: (query) => request("DELETE", `${URL.CATEGORY}/bulk`, query),

  ARCHIVE_RESTORE: (query) => request("PUT", URL.ARCHIVE, query),

  POSITION: (query) => request("PUT", URL.POSITION, query),

  PROTECTED: (query) => request("PUT", URL.PROTECTED, query),
};

export default liveTvCategoriesApi;
