import styles from "./style.module.scss";

const TableLocaleComponent = ({ click }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    click();
  };

  return (
    <div className={styles["locale-component"]}>
      <div className={styles["label-component-wrapper"]}>
        <div
          className={styles["locale-component-button"]}
          onClick={(e) => handleClick(e)}
        ></div>
        <span className={styles["locale-component-add"]}>Create New</span>
      </div>
    </div>
  );
};

export default TableLocaleComponent;
