import { useEffect, useState } from "react";

import { Drawer, Form, Input, notification } from "antd";

import REQUESTS from "../../../../../api/requests";
import ButtonComponent from "../../../../../_components/button/ButtonComponent";

export default function KeywordDrawer({ onClose, open, editable, getData }) {
    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setIsLoading(true);

        const body = {
            path: values.path,
            keywords: values.keywords,
            description: values.description,
        };

        if (editable) {
            body.id = editable.id;

            REQUESTS.KEYWORDS.EDIT(body)
                .then((response) => {
                    setIsLoading(false);

                    if (response.error) {
                        notification.error({
                            description: response.message,
                        });

                        return;
                    }

                    getData();
                    onClose();
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        } else {
            REQUESTS.KEYWORDS.ADD(body)
                .then((response) => {
                    setIsLoading(false);
                    if (response.error) {
                        notification.error({
                            description: response.message,
                        });
                        return;
                    }
                    getData();
                    onClose();
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        if (!open) {
            form.resetFields();
            setIsLoading(false);
        }

        if (editable) {
            form.setFields([
                { name: "path", value: editable.path },
                { name: "keywords", value: editable.keywords },
                { name: "description", value: editable.description },
            ]);
        }
    }, [open, editable]);

    return (
        <Drawer
            title={`${editable ? "Edit" : "Add"} keyword`}
            placement="right"
            onClose={onClose}
            open={open}
        >
            <Form
                form={form}
                name="keywords"
                layout="vertical"
                onFinish={onFinish}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        form.submit();
                    }
                }}
            >
                <Form.Item
                    label="Path"
                    name="path"
                    rules={[
                        {
                            required: true,
                            message: "Please input path",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: "Please input description",
                        },
                    ]}
                >
                    <Input.TextArea rows={5} />
                </Form.Item>

                <Form.Item
                    label="Keywords"
                    name="keywords"
                    rules={[
                        {
                            required: true,
                            message: "Please input keywords",
                        },
                    ]}
                >
                    <Input.TextArea rows={5} />
                </Form.Item>

                <Form.Item style={{ textAlign: "center" }}>
                    <ButtonComponent
                        title="Save"
                        actionType="save"
                        isLoading={isLoading}
                        onClick={() => form.submit()}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
}
