import { useEffect, useState } from "react";

import { Drawer, Form, Input, notification } from "antd";
import ButtonComponent from "../../../../_components/ButtonComponent";
import REQUESTS from "../../../../api/requests";

export default function NewFolderDrawer({ onClose, open, getData, currentPath }) {
    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setIsLoading(true);

        const body = {
            path: currentPath + values.name,
        };

        REQUESTS.VOD.TRANSCODING.FILE.ADD(body)
            .then((response) => {
                setIsLoading(false);

                if (response.error) {
                    notification.error({
                        description: response.message,
                    });

                    return;
                }
                getData(currentPath);
                onClose();
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (!open) {
            form.resetFields();
        }
    }, [open]);

    return (
        <Drawer title="Add new folder" placement="right" onClose={onClose} open={open}>
            <Form
                form={form}
                name="new-folder"
                layout="vertical"
                onFinish={onFinish}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        form.submit();
                    }
                }}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please input name",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item style={{ textAlign: "center" }}>
                    <ButtonComponent
                        title="Save"
                        actionType="save"
                        isLoading={isLoading}
                        onClick={() => form.submit()}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
}
