import { useEffect, useState } from "react";

import { Drawer, Form, Input, InputNumber, Space, notification } from "antd";

import REQUESTS from "../../../api/requests";

import ApkTabs from "./ApkTabs";

import ImageUpload from "../../../components/ImageUpload";

import ButtonComponent from "../../../_components/ButtonComponent";

import createLauncherAppFormData from "./createLauncherAppFormData";

export default function LauncherAppsDrawer({ onClose, open, editable, getData }) {
    const [isLoading, setIsLoading] = useState(false);

    const [icon, setIcon] = useState({
        url: null,
        file: null,
    });

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setIsLoading(true);

        const formData = createLauncherAppFormData({ values, editable, icon });

        REQUESTS.LAUNCHER.APPS.EDIT(formData)
            .then((response) => {
                setIsLoading(false);

                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });

                    return;
                }

                getData();
                onClose();
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (!open) {
            form.resetFields();
            setIcon({ url: null, file: null });
        }

        if (editable) {
            form.setFields([
                { name: "name", value: editable.name },
                { name: "app_id", value: editable.app_id },
                { name: "min_version", value: editable.min_version },
                { name: "build_number", value: editable.build_number },
                { name: "version_string", value: editable.version_string },
                { name: "apk", value: editable.apk },
            ]);

            if (editable.icon) {
                setIcon({ url: editable.icon, file: null });
            }
        }
    }, [open, editable]);

    return (
        <Drawer width={500} title="Launcher app" placement="right" onClose={onClose} open={open}>
            <Form
                form={form}
                name="launcher-apps"
                layout="vertical"
                onFinish={onFinish}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        form.submit();
                    }
                }}
                initialValues={{
                    apk: "test",
                }}
            >
                <Form.Item
                    name="icon"
                    rules={[
                        {
                            required: editable ? false : true,
                            message: "Please select icon",
                        },
                    ]}
                >
                    <ImageUpload image={icon} setImage={setIcon} />
                </Form.Item>

                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please input name",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <ApkTabs form={form} editable={editable} />

                <Form.Item label="App ID" name="app_id">
                    <Input />
                </Form.Item>

                <Space>
                    <Form.Item label="Build number" name="build_number">
                        <InputNumber controls={false} style={{ width: 145 }} />
                    </Form.Item>

                    <Form.Item label="Version string" name="version_string">
                        <InputNumber controls={false} style={{ width: 145 }} />
                    </Form.Item>

                    <Form.Item label="Min version" name="min_version">
                        <InputNumber controls={false} style={{ width: 145 }} />
                    </Form.Item>
                </Space>

                <Form.Item style={{ textAlign: "center" }}>
                    <ButtonComponent
                        title="Save"
                        actionType="save"
                        isLoading={isLoading}
                        onClick={() => form.submit()}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
}
